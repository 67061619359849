import { atom, DefaultValue, selector } from "recoil";
import {
  BusinessTypeEnum,
  SignupChapterEnum,
  SignupChapterValid,
  UserTypeEnum,
} from "./type";

export const statusState = atom({
  key: "status",
  default: SignupChapterEnum.UserType,
});

export const companyNameState = atom({
  key: "companyName",
  default: "",
});

export const signLangState = atom({
  key: "signLang",
  default: "",
});

export const brandNamesState = atom({
  key: "brandNames",
  default: {
    koBrandName: "",
    enBrandName: "",
    zhBrandName: "",
    jaBrandName: "",
  },
});

export const nameState = atom({
  key: "name",
  default: "",
});

const userEmailState = atom({
  key: "userEmail",
  default: "",
});

const passwordState = atom({
  key: "password",
  default: "",
});

export const businessTypeState = atom<BusinessTypeEnum>({
  key: "businessType",
  default: undefined,
});

export const contactState = atom({
  key: "contact",
  default: "",
});

const snsUrlState = atom({
  key: "snsUrl",
  default: "",
});

const documentsState = atom({
  key: "documents",
  default: [] as string[],
});

const contactAdditionalState = atom({
  key: "contactAdditional",
  default: "",
});

export const idCardState = atom<{ key: string; name: string }>({
  key: "idCard",
  default: null,
});

export const bankBookState = atom<{ key: string; name: string }>({
  key: "bankBook",
  default: null,
});

export const rrnState = atom({
  key: "rrn",
  default: "",
});

export const signDataUrlState = atom({
  key: "signDataUrl",
  default: "",
});

export const signLocaleState = atom({
  key: "signLocale",
  default: "",
});

export const signState = atom({
  key: "sign",
  default: "",
});

export const termState = atom({
  key: "term",
  default: "",
});

export const chapterState = atom({
  key: "chapterValid",
  default: {
    [SignupChapterEnum.Account]: false,
    [SignupChapterEnum.Contract]: false,
    [SignupChapterEnum.Partner]: false,
    [SignupChapterEnum.Payment]: false,
    [SignupChapterEnum.UserType]: false,
    [SignupChapterEnum.Confirm]: true,
  } as SignupChapterValid,
});

export const userTypeState = atom<UserTypeEnum>({
  key: "type",
  default: UserTypeEnum.Personal,
});

export const countryState = atom({
  key: "country",
  default: "",
});

export const promptModalVisibleState = atom<boolean>({
  key: 'promptModalVisible',
  default: true,
});

export const userTypeFormState = selector<{
  userType: UserTypeEnum;
  businessType: BusinessTypeEnum;
}>({
  key: "userTypeInformation",
  get: ({ get }) => {
    return {
      userType: get(userTypeState),
      businessType: get(businessTypeState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(userTypeState);
      reset(businessTypeState);
    } else {
      set(userTypeState, newValue.userType);
      set(businessTypeState, newValue.businessType);
    }
  },
});

export const accountFormState = selector<{
  userEmail: string;
  password: string;
}>({
  key: "acountForm",
  get: ({ get }) => {
    return {
      userEmail: get(userEmailState),
      password: get(passwordState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(userEmailState);
      reset(passwordState);
    } else {
      set(userEmailState, newValue.userEmail);
      set(passwordState, newValue.password);
    }
  },
});

export const contractFormState = selector<{
  name: string;
  companyName?: string;
  rrn?: string;
  country?: string;
}>({
  key: "contractForm",
  get: ({ get }) => {
    return {
      companyName: get(companyNameState),
      name: get(nameState),
      rrn: get(rrnState),
      country: get(countryState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(companyNameState);
      reset(nameState);
      reset(rrnState);
      reset(countryState);
    } else {
      set(companyNameState, newValue.companyName);
      set(nameState, newValue.name);
      set(rrnState, newValue.rrn);
      set(countryState, newValue.country);
    }
  },
});

export const partnerFormState = selector<{
  brandNames: {
    koBrandName?: string;
    enBrandName?: string;
    zhBrandName?: string;
    jaBrandName?: string;
  };
  contact: string;
  documents: string[];
  contactAdditional?: string;
  snsUrl?: string;
}>({
  key: "partnerForm",
  get: ({ get }) => {
    return {
      brandNames: get(brandNamesState),
      contact: get(contactState),
      documents: get(documentsState),
      contactAdditional: get(contactAdditionalState),
      snsUrl: get(snsUrlState),
    };
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(brandNamesState);
      reset(contactState);
      reset(documentsState);
      reset(contactAdditionalState);
      reset(snsUrlState);
    } else {
      set(brandNamesState, newValue.brandNames);
      set(contactState, newValue.contact);
      set(documentsState, newValue.documents);
      set(contactAdditionalState, newValue.contactAdditional);
      set(snsUrlState, newValue.snsUrl);
    }
  },
});

export const signinState = selector({
  key: "signin",
  get: ({ get }) => {
    return {
      ...get(userTypeFormState),
      ...get(accountFormState),
      ...get(contractFormState),
      ...get(partnerFormState),
      idCard: get(idCardState),
      bankBook: get(bankBookState),
      signLang: get(signLangState),
      signDataUrl: get(signDataUrlState),
      locale: get(signLocaleState),
    };
  },
});
