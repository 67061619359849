import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './VideoDialog.scss';

// 요청하기 위한 모달입니다.
export default function (props) {
    const { close, isShow, insertVideo } = props;
    const [url, setUrl] = useState('');
    const { t } = useTranslation();

    const handleInput = e => {
        setUrl(e.target.value);
    }
    const createVideoUrl = url => {
        // video url patterns(youtube, instagram, vimeo, dailymotion, youku, mp4, ogg, webm)
        const ytRegExp = /\/\/(?:(?:www|m)\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w|-]{11})(?:(?:[\?&]t=)(\S+))?$/;
        const ytRegExpForStart = /^(?:(\d+)h)?(?:(\d+)m)?(?:(\d+)s)?$/;
        const ytMatch = url.match(ytRegExp);

        const igRegExp = /(?:www\.|\/\/)instagram\.com\/p\/(.[a-zA-Z0-9_-]*)/;
        const igMatch = url.match(igRegExp);

        const vRegExp = /\/\/vine\.co\/v\/([a-zA-Z0-9]+)/;
        const vMatch = url.match(vRegExp);

        const vimRegExp = /\/\/(player\.)?vimeo\.com\/([a-z]*\/)*(\d+)[?]?.*/;
        const vimMatch = url.match(vimRegExp);

        const dmRegExp = /.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/;
        const dmMatch = url.match(dmRegExp);

        const youkuRegExp = /\/\/v\.youku\.com\/v_show\/id_(\w+)=*\.html/;
        const youkuMatch = url.match(youkuRegExp);

        const qqRegExp = /\/\/v\.qq\.com.*?vid=(.+)/;
        const qqMatch = url.match(qqRegExp);

        const qqRegExp2 = /\/\/v\.qq\.com\/x?\/?(page|cover).*?\/([^\/]+)\.html\??.*/;
        const qqMatch2 = url.match(qqRegExp2);

        const mp4RegExp = /^.+.(mp4|m4v)$/;
        const mp4Match = url.match(mp4RegExp);

        const oggRegExp = /^.+.(ogg|ogv)$/;
        const oggMatch = url.match(oggRegExp);

        const webmRegExp = /^.+.(webm)$/;
        const webmMatch = url.match(webmRegExp);

        const fbRegExp = /(?:www\.|\/\/)facebook\.com\/([^\/]+)\/videos\/([0-9]+)/;
        const fbMatch = url.match(fbRegExp);

        let src;
        console.log(ytMatch);
        if (ytMatch && ytMatch[1].length === 11) {
            const youtubeId = ytMatch[1];
            var start = 0;
            if (typeof ytMatch[2] !== 'undefined') {
                const ytMatchForStart = ytMatch[2].match(ytRegExpForStart);
                if (ytMatchForStart) {
                    for (var n = [3600, 60, 1], i = 0, r = n.length; i < r; i++) {
                        start += (typeof ytMatchForStart[i + 1] !== 'undefined' ? n[i] * parseInt(ytMatchForStart[i + 1], 10) : 0);
                    }
                }
            }
            src = '//www.youtube.com/embed/' + youtubeId + (start > 0 ? '?start=' + start : '');
        } else if (igMatch && igMatch[0].length) {
            src = 'https://instagram.com/p/' + igMatch[1] + '/embed/';
        } else if (vMatch && vMatch[0].length) {
            src = vMatch[0] + '/embed/simple';
        } else if (vimMatch && vimMatch[3].length) {
            src = '//player.vimeo.com/video/' + vimMatch[3];
        } else if (dmMatch && dmMatch[2].length) {
            src = '//www.dailymotion.com/embed/video/' + dmMatch[2];
        } else if (youkuMatch && youkuMatch[1].length) {
            src = '//player.youku.com/embed/' + youkuMatch[1];
        } else if ((qqMatch && qqMatch[1].length) || (qqMatch2 && qqMatch2[2].length)) {
            const vid = ((qqMatch && qqMatch[1].length) ? qqMatch[1] : qqMatch2[2]);
            src = 'https://v.qq.com/txp/iframe/player.html?vid=' + vid + '&amp;auto=0';
        } else if (mp4Match || oggMatch || webmMatch) {
            src = url;
        } else if (fbMatch && fbMatch[0].length) {
            src = 'https://www.facebook.com/plugins/video.php?href=' + encodeURIComponent(fbMatch[0]) + '&show_text=0&width=560';
        } else {
            // this is not a known video link. Now what, Cat? Now what?
            return false;
        }

        return src;
    }

    const onClickConfirmButton = e => {
        const data = createVideoUrl(url);
        
        const result = insertVideo(data);
        if (result) {
            close();
        }
    }
    
    return (
        <Modal className="requestDialog" show={isShow} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('addVideo')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="text"
                    onChange={handleInput}
                    placeholder={t('videoDialogDescription')}
                />
                <div className="requestDialog__description">
                    {t('videoDialogDescription2')}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="requestBtn" onClick={onClickConfirmButton}>
                    {t('addVideo')}
                </button>
            </Modal.Footer>
        </Modal>
    )
};