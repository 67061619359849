import styled from 'styled-components';
import { Title, Value } from '../../style';
import { Checkbox } from 'rsuite';

export const Wrap = styled.div`
    ${props => props.isDisp ? `pointer-events: none;` : ``}
    .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
        background-color: white;
        border: 1px solid black;
    }
    .rs-radio-wrapper::before {
        border: 1px solid black;
    }
    .rs-radio-wrapper .rs-radio-inner::after {
        width: 8px;
        height: 8px;
        background: black;
        margin-top: 4px;
        margin-left: 4px;
    }
`;

export const MinTitle = styled(Title)`
    padding-bottom: 6px !important;
`;
export const MinValue = styled(Value)`
    margin-bottom: 24px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`
export const CheckboxItem = styled(Checkbox)`
    font-size: 12px;
    margin-left: 6px;
`;