import styled from "styled-components";

// 모바일로 인식하는 디바이스 너비 제약
const mobileQuery = "Only screen and (max-width : 480px)";

export const Wrap = styled.div`
  ${props => props.isAdmin && `
    @media ${mobileQuery} {
      padding-top: 48px; 
    }
  `}
`;
export const Line = styled.div`
  width: 100%;
  float: left;
`;
export const Mobile = styled.div`
  display: none;
  text-align: center;
  @media ${mobileQuery} {
    display: block;
  }
`;
export const Title = styled.div`
  width: 100%;

  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export const Desktop = styled.div`
  @media ${mobileQuery} {
    display: none;
  }
  padding-bottom: 200px;
`;
