import { color } from "acon-mui/style";
import styled from "styled-components";
import ReloadImg from '../../img/reloadBlack.png';
export const Page = styled.div`
  margin-top: 30px;

  ${props => props.isAdmin && `{
    @media only screen and (max-width: 480px) {
      padding-top: 48px; 
    }
  }`}
  select.SearchForm {
    height: 35px;
  }

  .settle_periodSearch {
    display: flex;
    width: 350px;
    margin-bottom: 43px;
    select {
      min-width: 120px;
      margin-right: 10px;
    }
    label {
      margin-top: 7px;
      margin-left: -5px;
      margin-right: 5px;
    }
    & > div {
      flex-direction: column;
    }
    &__text {
      margin-right: 5px;
      margin-top: 7px;
    }
    button {
      margin-top: 2px;
      height: 28px;
    }
  }

  .settle_searchResult {
    width: 300px;
    margin-bottom: 20px;

    &__title {
      font-weight: 700;
      font-size: 14px;
      margin-right: 10px;
    }
    &__count {
      margin-top: 2px;
    }
  }

  .settle_searchOption {
    margin-top: 28px;
    margin-bottom: 10px;
  }
  .settle_excel {
    width: 100%;
    float: right;
    margin-top: 7px;
    & > div {
      width: 100%;
      text-align: right;
      float: right;
      margin-bottom: 10px;
    }
  }
  .settle_pageNavi {
    width: 100%;
    display: flex;
    text-align: center;
    margin-bottom: 30px;
  }

  .projectNameValue {
    &__personal {
      color: #c4c4c4;
    }
  }
  .dateRangePicker {
    margin-right: 10px;
  }

  tr > td {
    border-left: 0;
    border-right: 0;
    text-align: center;
  }
`;
export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const FlexColWrap = styled.div`
  display: flex;
  & > div {
    flex-direction: column;
  }
`;
export const Wrap = styled.div``;
export const Line = styled.div`
  width: 100%;
  float: left;
`;
export const Title = styled.div`
  width: 100%;

  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: ${color.text.primary};
  margin-bottom: 10px;
`;

export const ReloadButton = styled.button`
  width: 13px;
  height: 13px;
  padding: 0;
  background-image: url(${ReloadImg});
  background-size: 13px 13px;
  border: none;
  background-color: inherit;
  margin-left: 10px;
  margin-top: 8px;
`;
export const ExcelButtonWrap = styled.div`
  button {
    margin-right: 10px;
  }
`;
export const SearchResultBox = styled.div`
  padding-top: 24px;
  padding-bottom: 26px;
  padding-left: 34px;
  padding-right: 34px;
  text-align: right;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
`;
