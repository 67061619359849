import styled from 'styled-components';

const Input = styled.input`
    display: block;
    width: 100%;
    height: 36px;
    color: black;
    border: 0;
    border-bottom: 1px solid black;
    background: white;

    &[disabled]  {
        color: #A4A4A4;
    }

    &[type="password"] {
        font: caption;
        font-size: 30px;
    }
`;

const InputContainer = styled.div`
    display: flex;
    width: 100%;
    border: 1px solid ${props => props.isValid ? '#CDCDCD' : '#F300BA'};
    box-sizing: border-box;
    border-radius: 4px;

    &:focus {
        outline: none;
        border: 1px solid #333333;
    }
`;
const SimpleInput = styled.input`
    width: 100%;
    height: 48px;
    border: 0;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;

    padding-left: 16px;
    padding-right: 16px;

    &::placeholder {
        font-size: 14px;
    }
    &:focus {
        outline: none;
        border: 0;
    }
`;
const SimpleBorderInput = styled(SimpleInput)`
    border: 1px solid ${props => props.isValid ? '#333333' : '#F300BA'};

    &:focus {
        outline: none;
        border: 1px solid #333333;
    }

    &:placeholder-shown {
        border: 1px solid #CDCDCD;
    }
`;

const ShortInput = styled(Input)`
    display: inline-block;
    width: 150px;
`;

const ListInput = styled(ShortInput)`
    width: 190px;
    border: 1px solid #AAA;
`;

export { ShortInput, ListInput, InputContainer, SimpleInput, SimpleBorderInput };
export default Input;