// 구매 완료 유형 코드
export const UNIT_COMPLETE_CODE = 1;
// 구매 확정 유형 코드
export const UNIT_SETTLE_CODE = 2;
// 일반 리뷰 유형 코드
export const UNIT_TEXT_REVIEW_CODE = 3;
// 포토 리뷰 유형 코드
export const UNIT_PHOTO_REVIEW_CODE = 4;
// 게시글 리뷰 유형 코드
export const UNIT_BOARD_REVIEW_CODE = 5;
// 기본 설정 - 구매 완료 유형 코드
export const UNIT_DEFAULT_COMPLETE_CODE = 6;
// 기본 설정 - 리뷰 작성 유형 코드
export const UNIT_DEFAULT_REVIEW_CODE = 7;

// 카테고리 적용 설정 코드
export const APPLY_CATEGORY_CODE = 'category';
// 브랜드 적용 설정 코드
export const APPLY_BRAND_CODE = 'brand';
// 상품번호 적용 설정 코드
export const APPLY_GOODS_NO_CODE = 'goodsNo';
// 게시글 적용 설정 정의 코드
export const APPLY_BOARD_CODE = 'board';

// 포인트 설정 정액 정의 코드
export const PAY_UNIT_FIXED_CODE = 'fixed';
// 포인트 설정 정률 정의 코드
export const PAY_UNIT_PERCENT_CODE = 'percent';