import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalComponent as Modal } from "../components/modal";
import {
  Button,
  InfoLabel,
  PageTitle,
  FormGroup,
  Summary,
  FormTitle,
} from "../style";
import {
  LanguageCodeEnum,
  useAuthenticateMutation,
  useGetIsUsedEmailQuery,
  useSendAuthenticationCodeMutation,
} from "generated/graphql";
import OldRHFTextField from "./OldRHFTextField";
import { useForm } from "react-hook-form";
import { FormProvider } from "components/hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useRecoilState } from "recoil";
import { accountFormState, chapterState, promptModalVisibleState } from "signup/SignUp.atoms";
import { SignupChapterEnum } from "signup/type";
import Navigator from "signup/Navigator";
import { SignupTimer } from "signup/components/SignupTimer";

type AccountFormValues = {
  userEmail: string;
  authCode: string;
  password: string;
  checkPassword: string;
};

export default function AccountForm() {
  // 번역 도구
  const { t, i18n } = useTranslation();

  const getIsUsedEmail = useGetIsUsedEmailQuery({
    skip: true,
  });

  const [, setAccountForm] = useRecoilState(accountFormState);
  const [, setPromptModalVislble] = useRecoilState(promptModalVisibleState);

  // 인증 메시지
  const [authCodeMessage, setAuthCodeMessage] = useState<string>("");

  const [isInprogressEmailAuth, setIsInprogressEmailAuth] =
    useState<boolean>(false);

  const [isCompletedEmailAuth, setIsCompletedEmailAuth] =
    useState<boolean>(false);
  const [, setChapterValid] = useRecoilState(chapterState);
  // 타이머 초기화 트리거
  const [timerTrigger, setTimerTrigger] = useState(0);

  const [sendAuthenticationCode] = useSendAuthenticationCodeMutation();

  const [checkAuthenticationCode] = useAuthenticateMutation();

  // 모달
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const defaultValues: AccountFormValues = {
    userEmail: "",
    authCode: "",
    password: "",
    checkPassword: "",
  };

  const formSchema = Yup.object().shape({
    userEmail: Yup.string()
      .email(t("signupPage.emailIncorrect"))
      .required(t("signupPage.emailIncorrect"))
      .test("userEmail", t("signupPage.emailAlreadyUse"), (value) => {
        return new Promise((resolve, reject) => {
          getIsUsedEmail
            .refetch({
              account: value,
            })
            .then(({ data }) => {
              resolve(!data.existsUser);
            });
        });
      }),
    password: Yup.string().matches(
      /^(?=.*[\w ])(?=.*[\d])(?=.*[^ \w\d\t\r\n\v\f]).{8,}$/i,
      t("signupPage.passwordCondition")
    ),
    checkPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("signupPage.passwordIncorrect")
    ),
  });

  const methods = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(formSchema),
  });
  const {
    watch,
    formState: { isValid, errors, dirtyFields },
  } = methods;

  const values = watch();
  const isSuccess = isValid && isCompletedEmailAuth;

  const showModal = (message) => {
    window.scrollTo(0, 0);
    setModalMessage(message);
    setIsShowModal(true);
  };

  const handleSendEmail = async () => {
    // 코드 발송
    await sendAuthenticationCode({
      variables: {
        email: values.userEmail,
        lang: i18n.language,
      },
    });
    setIsInprogressEmailAuth(true);
    setTimerTrigger((prev) => prev + 1);
    setAuthCodeMessage("");
    // 모달 표시
    showModal(t("signupPage.sentEmail"));
  };

  const handleVerify = async () => {
    // 코드 확인
    const { data } = await checkAuthenticationCode({
      variables: {
        email: values.userEmail,
        code: values.authCode,
      },
    });

    if (data.authenticate) {
      setIsInprogressEmailAuth(false);
      setAuthCodeMessage("");
    } else {
      setAuthCodeMessage(t("signupPage.authCodeIncorrect"));
    }
    setIsCompletedEmailAuth(data.authenticate);
  };

  // 타이머 종료 이벤트 처리기 메소드
  const onEndTimer = () => {
    if (isCompletedEmailAuth) {
      return;
    }

    setAuthCodeMessage(t("signupPage.authCodeExpired"));
  };

  const getButtonText = () => {
    if (isCompletedEmailAuth) {
      return t("signupPage.verified");
    }
    if (isInprogressEmailAuth) {
      return t("signupPage.resend");
    }

    return t("signupPage.verify");
  };

  const setCurrentChapterValid = (valid: boolean) => {
    setChapterValid((prev) => {
      return {
        ...prev,
        [SignupChapterEnum.Account]: valid,
      };
    });
  };

  useEffect(() => {
    setCurrentChapterValid(isSuccess);
  }, [isSuccess]);

  // 국문이 아닐 경우 계정 정보에 내용이 없으면 페이지 이탈 시 prompt modal 노출 안됨.
  useEffect(() => {
    if (
      i18n.language === LanguageCodeEnum.Ko 
      || (values.userEmail && values.userEmail.length > 0)
      || (values.password && values.password.length > 0)
    ) {
      setPromptModalVislble(true);
    } else {
      setPromptModalVislble(false);
    }
  }, [values, i18n]);

  const handleNext: () => Promise<string> = async () => {
    return new Promise((resolve) => {
      setAccountForm({
        userEmail: values.userEmail,
        password: values.password,
      });
      resolve("");
    });
  };

  return (
    <>
      {isShowModal && (
        <Modal message={modalMessage} onConfirm={() => setIsShowModal(false)} />
      )}
      <FormProvider methods={methods}>
        <Stack spacing={7.5}>
          <Stack direction="row" justifyContent={"center"}>
            <PageTitle>{t("signupPage.accountInfomation")}</PageTitle>
          </Stack>
          <Stack spacing={4.5}>
            <FormGroup>
              <FormTitle>{t("signupPage.id")}</FormTitle>
              <Stack>
                <Stack direction="row">
                  <OldRHFTextField
                    name="userEmail"
                    error={!errors.userEmail}
                    disabled={isCompletedEmailAuth}
                    placeholder={"example@example.com"}
                  />
                  <Button
                    type="button"
                    disabled={
                      !values.userEmail ||
                      errors.userEmail ||
                      isCompletedEmailAuth
                    }
                    onClick={handleSendEmail}
                  >
                    {getButtonText()}
                  </Button>
                </Stack>
                {errors.userEmail && (
                  <Summary fail>{errors.userEmail.message}</Summary>
                )}

                {isCompletedEmailAuth && (
                  <InfoLabel>{t("signupPage.emailAuthenticated")}</InfoLabel>
                )}
                {values.userEmail &&
                  !isCompletedEmailAuth &&
                  !errors.userEmail &&
                  dirtyFields.userEmail && (
                    <Summary success>{t("signupPage.emailAvailable")}</Summary>
                  )}
                {isInprogressEmailAuth && !isCompletedEmailAuth && (
                  <Stack>
                    <Stack direction="row">
                      <div style={{ width: "100%", position: "relative" }}>
                        <OldRHFTextField
                          name="authCode"
                          error={!!authCodeMessage}
                          maxLength={6}
                          placeholder={t("signupPage.emailEnterAuthCode")}
                        />
                        <div
                          style={{
                            position: "absolute",
                            height: "100%",
                            top: 0,
                            right: 0,
                            fontSize: "12px",
                            color: "#F300BA",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "12px",
                          }}
                        >
                          <SignupTimer
                            range={600}
                            trigger={timerTrigger}
                            onEnd={onEndTimer}
                          />
                        </div>
                      </div>
                      <Button
                        type="button"
                        disabled={isCompletedEmailAuth}
                        onClick={handleVerify}
                      >
                        {t("signupPage.confirm")}
                      </Button>
                    </Stack>

                    {authCodeMessage && (
                      <Summary fail>{authCodeMessage}</Summary>
                    )}
                  </Stack>
                )}
              </Stack>
            </FormGroup>
            <FormGroup>
              <FormGroup>
                <FormTitle>{t("signupPage.password")}</FormTitle>
                <Stack>
                  <OldRHFTextField
                    name="password"
                    type="password"
                    placeholder={"**********"}
                  />
                  {values.password &&
                    !errors.password &&
                    dirtyFields.password && (
                      <Summary success>
                        {t("signupPage.passwordAvailable")}
                      </Summary>
                    )}
                  {values.password && errors.password && (
                    <InfoLabel color={"#F300BA"}>
                      {errors.password.message}
                    </InfoLabel>
                  )}
                </Stack>
              </FormGroup>
              <Stack>
                <OldRHFTextField
                  name="checkPassword"
                  type="password"
                  placeholder={t("signupPage.passwordRepeatPlaceholder")}
                />
                {!errors.checkPassword && values.checkPassword && (
                  <Summary success>{t("signupPage.passwordCorrect")}</Summary>
                )}
                {errors.checkPassword && values.checkPassword && (
                  <Summary fail>{t("signupPage.passwordIncorrect")}</Summary>
                )}
              </Stack>
            </FormGroup>
          </Stack>
        </Stack>
      </FormProvider>
      <Navigator onNext={handleNext} />
    </>
  );
}
