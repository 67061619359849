import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ChildToBlock } from 'common/style';
import { Flex, FlexItem } from 'signup/style';
import { Field } from './field';

export const Label = styled.label`
    > * {
        pointer-events: none;
    }
`;

export const CustomButton = styled.button`
    margin-left: 12px;
    padding: 9px 13px;
    border: 0;
    border-radius: 3px;
    background: #333;
    color: white;
    font-size: 14px;
`;

export const ValidationText = styled.div`
    color: #F300BA;
    font-size: 12px;
    margin-top: 5px;
`;

const SelectComponent = (props) => {
    if (!props.readOnly && !props.data) {
        throw new Error('must have data');
    }

    const ref = useRef();
    const [value, setValue] = useState(props.value || props.data && props.data[0]);

    const select = (val) => {
        ref.current.blur();
        setValue(val);
        if (props.onChange) {
            props.onChange(val);
        }
    }

    return (
        <div ref={ref} tabIndex="0" className={props.className} onClick={props.onClick}>
            <div className={props.className + 'wrap'}>
                <div className="selected">{props.readOnly ? props.value : value}</div>
                {!props?.disabled &&
                    <>
                        <div className="polygon"></div>
                        <div className="options">
                            {props.data?.map((val, idx) =>
                                <div
                                    key={idx}
                                    onClick={() => select(val)}
                                >{val}</div>
                            )}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

const SelectBoxComponent = (props) => {
    if (!props.readOnly && !props.data) {
        throw new Error('must have data');
    }

    const ref = useRef();
    const propValue = props.data.find(x => x.value === props.value)?.text
    const [value, setValue] = useState(propValue || props.data && props.data[0].text);

    const select = (item) => {
        ref.current.blur();
        setValue(item.text);

        if (props.onChange) {
            props.onChange(item.value);
        }
    };

    return (
        <div ref={ref} tabIndex="0" className={props.className} onClick={props.onClick}>
            <div className={props.className + 'wrap'}>
                <div className="selected">{props.readOnly ? propValue : value}</div>
                {!props?.disabled &&
                    <>
                        <div className="polygon"></div>
                        <div className="options">
                            {props.data?.map((item, idx) =>
                                <div
                                    key={idx}
                                    onClick={() => select(item)}
                                >
                                    {item.text}
                                </div>
                            )}
                        </div>
                    </>
                }
            </div>
            {props.isEmpty &&
                <ValidationText>This field is required</ValidationText>
            }
        </div>
    )
}

const SelectStyle = `
    :focus {
        z-index: 10;

        .options {
            display: block;
        }
    }

    .polygon {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 12px;
        top: 12px;

        ::before {
            content: '';
            display: block;
            position: absolute;
            left: calc(50% - 10px/2);
            top: calc(50% - 6px / 2);

            border: 5px solid transparent;
            border-bottom: 0;
            border-top: 6px solid black;
        }
    }

    .options {
        position: absolute;
        display: none;
        width: 100%;
        max-height: 240px;
        border-radius: 3px;
        border: 1px solid #CDCDCD;
        border-top: 0;
        border-bottom: 0;
        overflow-y: auto;

        > * {
            width: 100%;
            height: 40px;
            line-height: 40px;
            background: #FFFFFF;
            border-bottom: 1px solid #CDCDCD;
            padding-left: 16px;
            
            &:hover {
                background: #ffe3e3;
            }
        }
    }

    select {
        display: none;
    }
`;

export const Select = styled(SelectComponent)`
    position: relative;

    .selected {
        height: 40px;
        line-height: 40px;
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 3px;
        padding-left: 16px;

        ${props => props?.disabled && `
            border: 1px solid #CDCDCD;
            color: #7C7C7C;
        `}
    }

    ${SelectStyle}
`;

export const SelectBox = styled(SelectBoxComponent)`
    position: relative;

    .selected {
        height: 40px;
        line-height: 40px;
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 3px;
        padding-left: 16px;

        ${props => props?.disabled && `
            border: 1px solid #CDCDCD;
            color: #7C7C7C;
        `}

        ${props => props?.isEmpty && `
            border: 1px solid #F300BA;
        `}
    }

    ${SelectStyle}
`;

const InputContainer = styled.div`
    width: 100%;
`;

export const InputComponent = (props) => {
    let attrs = { ...props };

    delete attrs.number;

    function onInput(e) {
        if (props.number) {
            let value = e.target.value;

            if (isNaN(+value)) {
                value = value.replaceAll(/[^0-9\-]/g, '');
            }

            value = parseInt(value || 0);


            if (props.max && value > props.max) {
                value = props.max;
            }

            if (props.min && value < props.min) {
                value = props.min;
            }

            e.target.value = value;
        }
    };

    return (
        <InputContainer>
            <input ref={props?.inputRef ? props.inputRef : null} {...attrs} onInput={onInput} />
            {props?.isEmpty &&
                <ValidationText>This field is required</ValidationText>
            }
        </InputContainer>
    )
}

export const Input = styled(InputComponent)`
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    border: 1px solid #000000;
    padding-left: 16px;

    ${props => props.disabled && `
        border: 1px solid #cdcdcd;
        background-color: white;
        color: #7C7C7C;
    `}

    ${props => props.isEmpty && `
        border: 1px solid #F300BA;
    `}

    &[disabled] {
        pointer-events: none;
    }

    &[hidden] {
        display: none;
    }

    &[readonly] {
        border: 1px solid #cdcdcd;
        background-color: white;
        color: #7C7C7C;
    }
`;

export const InputField = (props) => {
    return (
        <Field label={props.label}>
            <Input {...props} />
        </Field>
    )
}

export const Textarea = styled.textarea`
    resize: none;
    padding: 14px 16px;
    border: 1px solid #000000;

    ${props => props.disabled && `
        border: 1px #cdcdcd solid;
        background-color: white;
        color: #7c7c7c;
    `}

    ${props => props.isEmphasis && `
        border: 1px #F300BA solid;
    `}
`;

export const FileInputComponent = (props) => {
    const id = Math.random().toString(36).substr(2, 11);

    return (
        <Flex>
            <FlexItem>
                <Input hidden type="file" id={id} onChange={props.onChange} />
                <ChildToBlock>
                    <Label htmlFor={id}>
                        <Input disabled={props?.disabled || false} value={props.value} />
                    </Label>
                </ChildToBlock>
            </FlexItem>
            <Label htmlFor={id}>
                <CustomButton>{props?.isGlobal ? 'Upload' : '업로드'}</CustomButton>
            </Label>
        </Flex>
    )
};

export const MaskingInputComponent = (props) => {
    const [value, setValue] = useState([]);

    const onChangeItem = (e) => {
        const target = e.target;
        const newValue = [...value];

        newValue[+target.dataset.idx] = target.value;

        setValue(newValue);

        props.onChange(e, newValue.join(props.split));
    };

    useEffect(() => {
        const value = props.defaultValue || props.value || '';

        setValue(value.split(props.split));
    }, []);

    return (
        <div className={props.className}>
            {value.map((item, idx) => {
                const attrs = {};

                if (idx === (+props.masking)) {
                    attrs.type = "password";
                }

                return <React.Fragment key={idx}>
                    {idx > 0 &&
                        <span>{props.split}</span>
                    }
                    <input
                        {...attrs}
                        data-idx={idx}
                        maxLength={props.maxLength[idx]}
                        onChange={onChangeItem}
                        value={item}
                    />
                </React.Fragment>
            })}
        </div>
    );
};

export const MaskingInput = styled(MaskingInputComponent)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0 2px;
    line-height: 40px;
    border-radius: 3px;
    border: 1px #000000 solid;

    ${props => props.disabled && `
        border: 1px #cdcdcd solid;
    `}

    span {
        padding: 0 15px;
        font-size: 1.2em;
        font-weight: bold;
    }

    input {
        all: unset;
        flex: 1;
    }
`;

export const MaskingInputField = (props) => {
    return (
        <Field label={props.label}>
            <MaskingInput {...props} />
        </Field>
    )
};

export const UploadInput = styled(Input)`
    width: 80%;  
`;

const UploadButton = styled(CustomButton)`
    ${props => props.isActive && `
        opacity: 0.7;
    `}
`;

export const FileListInputComponent = (props) => {
    const id = Math.random().toString(36).substr(2, 11);
    const fileName = props.value || '';

    return (
        <Flex style={{ pointerEvents: fileName ? 'none' : 'inherit' }}>
            <FlexItem>
                <Input hidden type="file" id={id} onChange={props.onChange} />
                <ChildToBlock>
                    <Label htmlFor={id}>
                        <Input isEmpty={props?.isEmpty} value={fileName} />
                    </Label>
                </ChildToBlock>
            </FlexItem>
            <Label htmlFor={id}>
                <UploadButton isActive={fileName}>{props?.isGlobal ? 'Upload' : '업로드'}</UploadButton>
            </Label>
        </Flex>
    )
};