import { createPortal } from 'react-dom';
import React from 'react';
import Spinner from '../../../spinner/Spinner';
import styled from 'styled-components';
import TextareaAutoSize from 'react-textarea-autosize';
import { alpha, styled as muiStyled, Switch } from '@mui/material';

export const FlexWrap = styled.div`
  width: 1400px;
  position: absolute;
  left: calc(50% - 700px);
  display: flex;
  justify-content: space-between;
`;
const LoadingWrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 105;
`;
const SpinnerWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Loading = ({ show }) => {
  if (!show) return <></>;
  return createPortal(
    <LoadingWrap>
      <SpinnerWrap>
        <Spinner color="#ff007b" size="48" />
      </SpinnerWrap>
    </LoadingWrap>,
    document.body,
  );
};

export const Wrap = styled.div`
  margin: 0 auto;
  ${(props: { fullWidth?: boolean }) => (props.fullWidth ? 'width: 100%;' : 'width: 592px;')}
  padding-top: 10px;
  padding-bottom: 120px;
  min-height: 707px;

  line-height: 20px;
  font-size: 14px;
  font-weight: 400;

  ${(props: { isDisp?: boolean }) =>
    props.isDisp
      ? `
        input[type=text] {
            background-color: #ccc;
        }
        
    `
      : ``}
`;

export const Input = styled.input.attrs((props: { type?: string; disabled?: boolean }) => ({
  type: props.type ? props.type : 'text',
  disabled: props.disabled ? props.disabled : false,
}))`
  width: 100%;
  padding: 9.5px 16px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;

  & ~ input,
  & ~ button {
    margin-left: 12px;
  }
`;

export const Empty = styled.div`
  width: 100%;
  height: ${(props: { height?: number | string; ishide?: string; isadmin?: string }) => (props.height ? props.height : `0`)}px;
  ${(props: { height?: number | string; ishide?: string; isadmin?: string }) => (props.ishide ? 'display: none;' : '')}
`;

/* 필드 */
export const Field = styled.div`
  width: 100%;
  height: auto;
  margin-top: 25px;
  font-weight: bold;

  ${(props: { type?: string; isDisp?: boolean }) => (props.type === 'col-4' ? 'width: 33px;' : '')}
  ${(props: { type?: string; isDisp?: boolean }) => (props.isDisp ? `pointer-events: none;` : ``)}
    & > ${Input} {
    font-size: 16px;
  }

  & > * {
    margin-bottom: 16px;
  }
`;
export const Tab = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
`;
export const TabItem = styled.div`
  font-size: 14px;
  color: #333;
  line-height: 100%;
`;
export const TabDivision = styled.div``;
export const Flex = styled.div`
  display: flex;
  ${(props: { type?: string; align?: string }) => (props.type === 'space-between' ? 'justify-content: space-between;' : '')}
  ${(props: { type?: string; align?: string }) => (props.align === 'center' ? 'align-items: center;' : '')}
`;
export const Div = styled.div``;

type PaddingType = { vertical?: string; horizontal?: string };
export const Padding = styled.div`
  padding-top: ${(props: PaddingType) => (props.vertical ? props.vertical : `2.5`)}px;
  padding-bottom: ${(props: PaddingType) => (props.vertical ? props.vertical : `2.5`)}px;
  padding-left: ${(props: PaddingType) => (props.horizontal ? props.horizontal : `2.5`)}px;
  padding-right: ${(props: PaddingType) => (props.horizontal ? props.horizontal : `2.5`)}px;
`;
export const Select = styled.select`
  text-align-last: center;
  font-size: 20px;
  width: 100%;
  height: 40px;
  font-weight: bold;
`;

type SpanType = { size?: number; bold?: boolean };
export const Span = styled.span`
  ${(props: SpanType) => (props.size ? `font-size:${props.size}px;` : '')};
  ${(props: SpanType) => (props.bold ? `font-weight: bold;` : '')};
`;
export const Status = styled.span`
  ${(props: { active?: boolean }) => (props.active ? `font-weight: 700; color: #ff007b;` : ``)}
  font-size: 12px;
`;

/* 제목 컴포넌트  */
type TitleType = { admin?: boolean; warning?: boolean };
export const Title = styled.div`
  font-weight: bold;
  position: relative;
  & > span {
    margin-top: 2px;
  }
  ${(props: TitleType) => (props.admin ? `color: #0A2173;` : ``)}
  ${(props: TitleType) => (props.warning ? `color: red;` : ``)}
`;
export const Item = styled.div`
  & > ${Title} {
    font-size: 14px;
    padding-bottom: 16px;
  }
  width: 100%;
  margin-bottom: 36px;
`;
export const Box = styled.div<{ height?: string | number }>`
  width: 100%;
  ${(props) => (props.height ? `min-height: ${props.height}px;` : ``)}
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 60px 77px 36px 77px;
`;
export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  font-size: 12px;
  color: #767676;
  font-weight: 400;
`;
export const Value = styled.div`
  font-size: 12px;
  font-weight: 400;
  position: relative;
`;

export const LargeThumbnail = styled.div``;
export const Right = styled.div`
  float: right;
`;
export const BalloonDescription = styled.div`
  font-size: 11px;
  font-weight: 500;
  margin-left: 10px;
  letter-spacing: -0.02em;
  display: inline-block;
  border-radius: 16px;
  background-color: #f5f5fd;
  padding: 7.5px 14px;
  text-align: center;
  line-height: 100%;
`;

export const DivInput = styled(TextareaAutoSize)`
  width: 100%;
  min-height: 41px;
  resize: none;
  padding: 9.5px 16px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
`;

export const InputLimitText = styled.span`
  width: 50px;
`;

export const LangTabs = styled.div`
  display: flex;
`;

export const LangTab = styled.div`
  font-size: 20px;
  line-height: 29px;
  font-weight: bold;
  color: #575757;
  width: 125px;
  text-align: center;
  cursor: pointer;
  border-bottom: 10px solid #${(props: { active?: boolean }) => (props.active ? `EB357B` : 'C4C4C4 ')};
`;

export const VersionContainer = styled.div`
  border: 1px solid #cdcdcd;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 32px;
  font-size: 14px;
`;

export const PackageSwitch = muiStyled(Switch)(({ theme }) => ({
  transform: 'translateX(10px)',

  '& .MuiSwitch-thumb': {
    boxShadow: '0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#F300BA',
    '&:hover': {
      backgroundColor: alpha('#F300BA', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#00000033',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#FFBAFA80',
  },
}));
