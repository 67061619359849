import { BlackButton, WhiteButton } from 'components/button';
import styled from 'styled-components';

export const Flex = styled.div`
    display: flex;
    margin-bottom: 16px;
    ${props => props.type === 'space-between' ? 'justify-content: space-between;' : ''}
    ${props => props.justifyContent ? `justify-content: ${props.justifyContent};` : ''}
    ${props => props.align === 'center' ? 'align-items: center;' : ''}
`;
export const Wrapper = styled.div`
    width: 600px;
    margin: 0 auto;
    padding-top: 36px;
`;
export const Top = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 26px;
`;

export const ColumnLink = styled.a`
    display: flex;
    color: #333;
    &: hover {
        color: inherit;
    }
    ${props => props.flex ? `flex: ${props.flex};` : ``}
`;

export const ButtonWrap = styled.div`
    align-items: center;
    display: flex;

    button {
        margin-left: 5px;
    }
`;
export const WriteButton = styled(BlackButton)`
    width: 132px;
    height: 40px;
    & > a { 
        color: inherit;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    & > a:hover {
        color: inherit;
    }
`;

export const RemoveButton = styled(WhiteButton)`
    width: 132px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

    &:disabled {
        opacity: 0.5;
    }
    & > a { 
        color: black;
    }
    & > a:hover {
        color: inherit;
    }
`;

export const Field = styled.div`
    margin-bottom: 80px;
`;

export const Title = styled.div`
    color: #333333;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
`;

export const Value = styled.div`

`;

export const Label = styled.div`
    color: black;
    width: 50px;
    margin-right: 56px;
`;

export const SelectContainer = styled.div`
    width: 100%;
`;
export const Select = styled.select`
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    box-sizing: border-box;
    border-radius: 3px;
    color: black;
    text-align-last: left;
    padding-left: 12px;
`;
export const Option = styled.option`
`;