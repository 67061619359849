import React, { useState } from 'react';
import QueryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { SelectBox } from 'acon-mui/components';
import { TextField } from '@mui/material';
import { color } from 'acon-mui/style';
import { isMobile } from 'react-device-detect';
import { ReactComponent as SearchIcon } from 'acon-mui/icons/icon-search.svg';

export default (({ history }) => {
    // 번역도구 
    const { t } = useTranslation();

    // 검색옵션 
    const [searchOption, setSearchOption] = useState({
        keyword: `${t('goodsNm')}`,
        type: 'goodsNm'
    });

    // 검색 옵션 배열
    const options = [
        { id: 0, value: 'goodsNm', label: t('goodsNm') },
        { id: 1, value: 'penNm', label: t('PenNm') }
    ];

    // input 태그 keyup 이벤트 처리기 메소드 
    const onKeyUp = (e) => {
        try {
            // 입력한 키가 엔터일 경우 
            if (e.key === 'Enter') {
                // 검색 실행 
                redirectSearched()
            }
        }
        catch (errObj) {
            // 실패 메세지
            errObj.message = `판매내역 정보를 검색하는 도중(엔터), 예상치 못한 예외 발생(${errObj.message})`;
            // 실패 로그 기록(TODO)
            console.log(errObj);
            // 사용자 안내 메세지 표시(TODO)
            alert('관리자에게 문의해주세요.');
        }
    }
    // 검색 옵션 변경 이벤트 처리기 메소드 
    const onChange = (e) => {
        setSearchOption({
            ...searchOption,
            keyword: e.target.value
        });
    };
    const onSelect = (e) => {
        setSearchOption({
            ...searchOption,
            type: options.find(x => x.id === e.target.value).value
        });
    };
    // 검색된 페이지로 리다이렉트하기 메소드 
    const redirectSearched = () => {
        // 검색 키워드
        const searchKeyword = searchOption.keyword.trim();
        // url 파라메터 객체 
        let urlParamObj = QueryString.parse(history.location.search);
        urlParamObj.page = '1';
        urlParamObj.brandId = `${urlParamObj.brandId}`;
        delete urlParamObj.goodsNm;
        delete urlParamObj.penNm;

        // 검색 키워드가 존재하는 경우 
        if (searchKeyword) {
            urlParamObj[searchOption.type] = encodeURI(searchKeyword);
        }
        // 검색 쿼리 문자 
        const queryStr = QueryString.stringify(urlParamObj)
        // 검색페이지로 이동 
        history.push(`?${queryStr}`);
    };

    // submit 이벤트 처리기 메소드 
    const onSubmit = () => {
        try {
            redirectSearched();
        }
        catch (errObj) {
            // 실패 메세지
            errObj.message = `상품정보를 검색하는 도중, 예상치 못한 예외 발생(${errObj.message})`;
            // 실패 로그 기록(TODO)
            console.log(errObj);
            // 사용자 안내 메세지 표시(TODO)
            alert('관리자에게 문의해주세요.');
        }
    };

    return (
        <>
            <SelectBox
                defaultValue={0}
                options={options.map((x) => ({ id: x.id, label: x.label }))}
                onSelect={onSelect}
                {...isMobile ? { 
                    marginTop: '16px'
                } : {
                    width: "160px",
                    marginLeft: "16px",
                    flexShrink: '0'
                }}
            />
            <TextField
                id="settle-table-search"
                placeholder={t('settle.inputSearchKeyword')}
                fullWidth
                onChange={onChange}
                onKeyUp={onKeyUp}
                InputProps={{
                    startAdornment: <SearchIcon style={{ marginRight: '8px' }} />
                }}
                sx={{
                    ...(isMobile ? {
                        mt: 2,
                        '& input': {
                            p: '8.5px 14px'
                        }
                    } : {
                        ml: 2,
                    }),
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: color.primary,
                        },
                    },
                }}
            />
        </>
    );
});