import styled from 'styled-components';

export const Page = styled.section`
    color: #4A4A4A;
    background: white;
    padding: 90px 50px;

    & > h1 {
        word-break: keep-all;
    }
`;

export const Button = styled.button`
    width: ${props => props.width ?? '200px'};
    height: 36px;
    border: 0;
    border-radius: 3px;
    font-size: 16px;
    color: white;
    background: #333;
`;

export const SmallButton = styled(Button)`
    box-sizing: border-box;
    width: auto;
    height: 28px;
    padding: 0 14px;
    font-size: 12px;
`;

export const SmallWhiteButton = styled(SmallButton)`
    color: #333;
    border: 1px solid #7C7C7C;
    background: white;
`;

export const VividButton = styled(Button)`
    background: #FF007B;
`;

export const MarginCenter = styled.div`
    display: inline-block;
    margin: 0 auto;
`;

export const ChildToBlock = styled.div`
    > * {
        display: block;
    }
`;