import React, { Fragment, useContext } from 'react';
import { AppContext } from 'app';
import { Wrap } from './style';
import { color } from 'acon-mui/style';
import { Box as MuiBox, Typography } from '@mui/material';
import { RecoilRoot } from 'recoil';
import BrandPick from './components/BrandPick/BrandPick';
import InformationCard from 'acon-mui/components/Board/InformationCard';
import { Field } from 'acon-mui/components/Board';
import { useTranslation } from 'react-i18next';

const BrandPage = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <RecoilRoot>
      <Wrap fullWidth>
        <Field>
          <MuiBox display="flex" alignItems="center">
            <Typography mr="auto" color={color.text.primary} fontWeight="700" fontSize="24px" lineHeight="36px">
              {t('BrandPage.title')}
            </Typography>
          </MuiBox>
        </Field>
        <Field
          informationCards={
            <Fragment>
              <InformationCard>{t('BrandPage.brandPick.maximumDescription')}</InformationCard>
              <InformationCard marginTop="20px">{t('BrandPage.brandPick.sortDescription')}</InformationCard>
            </Fragment>
          }
        >
          <BrandPick />
        </Field>
      </Wrap>
    </RecoilRoot>
  );
};

export default BrandPage;
