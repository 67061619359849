import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const browserLanguageCode = navigator.language.split('-')[0];
const language = ['ko', 'en', 'zh', 'ja'].includes(browserLanguageCode) ? browserLanguageCode : 'en';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    // 브라우저 언어에서 추출 TODO: v2 처럼 변경되어야 함.
    lng: language,
    backend: {
      loadPath: `/assets/i18n/${language}.json`,
    },
    fallbackLng: 'en',
    debug: false,
    react: {
      useSuspense: false,
    },
  });
export default i18n;
