import AconHelper from './global';

export const isKorean = (lang) => {
	return lang.startsWith('ko');
};

export const translateCount = (amount, lang) => {
	if (isKorean(lang)) {
		return amount + '개';
	} else {
		return amount
	}
}

export const displayMoneyByLang = (amount, lang) => {
	try {
		if (isKorean(lang)) {
			return AconHelper.AddCommas(amount) + '원';
		} else {
			return '$' + AconHelper.AddCommas(convertPrice(amount, 'ko', 'en'));
		}
	} catch (e) {
		console.error(e);
		throw e;
	}
}

export const convertPrice = (price, toLang, fromLang) => {
	price = Number(price);
	toLang = languageCodeToCountry(toLang);
	fromLang = languageCodeToCountry(fromLang);

	if (toLang === fromLang) return price;

	const langs = ['ko', 'en', 'cn', 'jp'];
	// 고정환율들
	const measure = [1000, 1, 6.25, 100];

	return (
		(price * measure[langs.indexOf(fromLang)]) /
		measure[langs.indexOf(toLang)]
	).toFixed(2);
}

export const languageCodeToCountry = (languageCode) => {
	if (languageCode === 'zh' || languageCode === 'cn') {
		return 'cn';
	}

	if (languageCode === 'ja' || languageCode === 'jp') {
		return 'jp';
	}

	// 기타국가 언어일경우 임시로 en으로 반환
	if (!['ko', 'en'].includes(languageCode)) return 'en';

	return languageCode;
}
