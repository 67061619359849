import React from 'react';
import styled from 'styled-components';

const FieldContents = styled.div`margin-top: 16px;`;

const Component = (props) => {
    return (
        <div className={props.className}>
            <strong>{props.label}</strong>

            <FieldContents>
                {props.children}
            </FieldContents>
        </div>
    )
}

export const Field = styled(Component)`
    &:not(:first-child) {
        margin-top: 36px;
    }

    strong {
        display: inline-block;
        font-size: 14px;
    }

    span {
        margin-left: 10px;
    }
`;

export const SelectWrapper = styled.div`
    ${props => props?.isEmpty && `
        border: 1px solid #F300BA;
    `}
    border-radius: 5px;
`;

export const SubLabel = styled.div`
    font-size: 12px;
    color: #FA0070;
    font-weight: 400;
`;