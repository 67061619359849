import React from 'react';
import styled from 'styled-components';

export const Wrap = styled.div`
    width: 256px;
    padding-top: 15px;
    padding-bottom: 10px;
    position: absolute;
    box-shadow: 4px 12px 18px rgba(0, 0, 0, 0.24);
    border-radius: 3px;
    background-color: white;
    height: 200px;
    overflow-y: auto;
    margin-left: ${props => props.left}px;
    ${props => props.zIndex ? `z-index: ${props.zIndex};` : ''}
    margin-top: 10px;

    input[type=text] {
        width: calc(100% - 20px);
        margin: 0 auto;
        margin-bottom: 10px;
        color: black;
    }
`;

export const InitWrap = styled.div`
    width: 100%;
    border-top: 1px solid rgb(244, 244, 244);
    padding-top: 10px;

    button {
        color: black;
        border: 0;
        background-color: inherit;
        float: right;
        font-size: 16px;
    }
`;
export const Item = (({ value, label, isChecked, setIds }) => {
    const FilterItem = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        height: 32px;
        padding-left: 10px;
        padding-right: 10px;

        input {
            margin-top: -3px;
            margin-right: 10px;
        }
        label {
            font-weight: 400;
            color: black;
            margin-top: 9px;
        }

        &:hover {
            background-color: rgba(244,244,244,1);
        }
    `;
    const guid = (() => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    })();
    // checkbox 변경 이벤트 처리기 메소드 
    const onChange = e => {
        setIds(
            e.target.value,
            e.target.checked
        );
    };
    return (
        <FilterItem>
            <input type="checkbox" value={value} id={`${guid}-${value}`} onChange={onChange} checked={isChecked} />
            <label htmlFor={`${guid}-${value}`}>{label}</label>
        </FilterItem>
    )
});