import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LanguageCodeEnum, RegularCategoryType, useDescendantsOfRegularCategoryQuery } from "generated/graphql";
import { Box, Typography } from '@mui/material';
import { categoryTreeSelector } from "boards/DetailBoardWrite/recoil/selectors/categoryTree";
import CategoriesRow from "./CategoriesRow";
import { gameState, webtoonState } from "boards/DetailBoardWrite/recoil";

export default () => {
  // 번역도구
  const { t } = useTranslation();
  // URL 파생 정보
  const { lang } = useParams<{ lang?: LanguageCodeEnum; }>();
  const webtoon = useRecoilValue(webtoonState);
  const game = useRecoilValue(gameState);

  const { data: genreData, loading: isGenreDateLoading } = useDescendantsOfRegularCategoryQuery({
    variables: { 
      descendantsOfRegularCategoryInput: {
        languageCode: lang,
        regularCategoryType: RegularCategoryType.Genre,
      }
    },
  });
  const { data: themeData, loading: isThemeDateLoading } = useDescendantsOfRegularCategoryQuery({
    variables: { 
      descendantsOfRegularCategoryInput: {
        languageCode: lang,
        regularCategoryType: RegularCategoryType.Theme,
      }
    },
  });
  const { data: gameData, loading: isGameDateLoading } = useDescendantsOfRegularCategoryQuery({
    variables: { 
      descendantsOfRegularCategoryInput: {
        languageCode: lang,
        regularCategoryType: RegularCategoryType.Game,
      }
    },
  });
  
  // 로딩중 여부
  const isLoading = !(
    !isGenreDateLoading && 
    !isThemeDateLoading &&
    !isGameDateLoading);

  const setCategoryTree = useSetRecoilState(categoryTreeSelector);

  useEffect(() => {
    if (!isLoading) {
      setCategoryTree({
        genre: genreData.descendantsOfRegularCategory,
        theme: themeData.descendantsOfRegularCategory,
        game: gameData.descendantsOfRegularCategory,
      });
    }
  }, [isLoading, genreData, themeData, gameData]);

  return (
    <Box mt={3}>
      {(webtoon || game) && (
        <Typography
          color="#000000DE"
          fontWeight="500"
          fontSize="14px"
          lineHeight="22px"
          letterSpacing="0.1px"
        >
          {t("document.categories.title")}
        </Typography>
      )}
      {webtoon && (
        <>
          <Typography
            mt={1.5}
            color="#000000DE"
            fontWeight="700"
            fontSize="14px"
            lineHeight="22px"
            letterSpacing="0.1px"
          >
            [{t("document.toon")}] {t("document.categories.title")}
          </Typography>
          <CategoriesRow type="genre" />
          <CategoriesRow type="theme" />
        </>
      )}
      {game && (
        <>
          <Typography
            mt={1.5}
            color="#000000DE"
            fontWeight="700"
            fontSize="14px"
            lineHeight="22px"
            letterSpacing="0.1px"
          >
            [{t("document.game")}] {t("document.categories.title")}
          </Typography>
          <CategoriesRow type="game" />
        </>
      )}
    </Box>
  );
};
