import { atom } from "recoil";
import { CategoryTree } from "./class";

export const categoryTreeState = atom<{
  genre?: CategoryTree[];
  theme?: CategoryTree[];
  game?: CategoryTree[];
}>({
  key: 'categoryTreeState',
  default: {
    genre: undefined,
    theme: undefined,
    game: undefined,
  }
});