import React, { useContext } from 'react';
import { Button } from 'acon-mui/components';
import { BrandPickConfirmModalContainer, BrandPickConfirmModalBody, BrandPickConfirmModalSaveWrap } from './BrandPickConfirmModal.styles';
import { IBrandPickConfirmModalProps } from './BrandPickConfirmModal.types';
import { Typography } from '@mui/material';
import { color } from 'acon-mui/style';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useMyBrandQuery } from 'generated/graphql';

const BrandPickConfirmModal = ({ isShow, onClose, onClick }: IBrandPickConfirmModalProps): React.ReactElement => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { data: myBrand } = useMyBrandQuery();
  const brandName = _.find(myBrand?.myBrand?.i18ns || [], (item) => item?.languageCode === i18n.language)?.name;

  if (!myBrand?.myBrand) return <></>;
  return (
    <BrandPickConfirmModalContainer centered show={isShow} onHide={onClose}>
      <BrandPickConfirmModalBody>
        <Typography align="center" fontSize={18} fontWeight={700} color={color.text.primary}>
          {t('BrandPage.saveInfo')}
        </Typography>
        <Typography
          align="center"
          fontSize={14}
          fontWeight={400}
          color={color.text.primary}
          dangerouslySetInnerHTML={{ __html: t('BrandPage.saveInfoDescription', { brandName: brandName }) }}
        />
        <BrandPickConfirmModalSaveWrap>
          <Button colorTheme="primary" variant="outlined" onClick={onClose} disabled={false} minWidth="170px" width="fit-content" borderRadius="4px">
            {t('BrandPage.no')}
          </Button>
          <Button colorTheme="primary" variant="contained" onClick={onClick} disabled={false} minWidth="170px" width="fit-content" borderRadius="4px">
            {t('BrandPage.yes')}
          </Button>
        </BrandPickConfirmModalSaveWrap>
      </BrandPickConfirmModalBody>
    </BrandPickConfirmModalContainer>
  );
};

export default BrandPickConfirmModal;
