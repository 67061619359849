import { graphqlUrl, uploadFile } from "api";
import axios from "axios";
import ACON from "lib/global";

const makeForm = (file, category, docId, data) => {
  let query = {
    query: `mutation($input:InputImage!) {
        uploadImage(input:$input) {
            message
            resultCode
            data
        }
        }`,
    variables: {
      input: {
        docId: docId,
        category: category,
        data: data || "",
      },
    },
  };

  let map = {
    0: ["variables.input.data"],
  };
  let fd = new FormData();
  fd.append("operations", JSON.stringify(query));
  fd.append("map", JSON.stringify(map));
  fd.append(0, file, file.name);
  return fd;
};

const sendFile = async (file) => {
  const { key } = await uploadFile(file, true);
  const name = key.split("/").pop();

  let query = {
    query: `mutation($key: String!, $name: String!) {
                uploadEditorImage(key: $key, name: $name)
            }`,
    variables: {
      key,
      name,
    },
  };
  const { data } = await axios.post(graphqlUrl, query);

  return `${process.env.STORAGE_ORIGIN}/${data.data.uploadEditorImage}`.replace(
    /(https?:\/\/)|(\/)+/g,
    "$1$2"
  );
};

const sendFiles = async (files) => {
  // 파일 크기 배열
  const fileSizeArr = [...files].map((x) => x.size);
  // 파일 크기가 10MB 이상인 항목들
  const oversizeFileArr = fileSizeArr.filter((n) => n / 1024 / 1024 > 10);

  if (oversizeFileArr.length > 0) {
    throw new ACON.ValidationError("common.fileUploadLimit");
  }

  let urls = [];

  for (let i = 0; i < files.length; i++) {
    const url = await sendFile(files[i]);

    urls.push(url);
  }

  return urls;
};

export { sendFiles, sendFile, makeForm };
