import { Checkbox, FormControlLabel, TextField, FormGroup } from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BrandPickListItemText } from '../BrandPick/BrandPick.styles';
import { BrandPickModalBody, BrandPickModalContainer, BrandPickModalList, BrandPickModalListItem, BrandPickModalSaveWrap } from './BrandPickModal.styles';

import { IBrandPickModalProps, IPickList } from './BrandPickModal.types';
import { ReactComponent as CircleCheckedIcon } from 'acon-mui/icons/icon-circle-checked.svg';
import { ReactComponent as SearchIcon } from 'acon-mui/icons/icon-search.svg';
import { Button } from 'acon-mui/components';
import { useBrandAssetsQuery } from 'generated/graphql';
import { AppContext } from 'app';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import BrandPickModalLoading from '../BrandPickModalLoading/BrandPickModalLoading';

const BrandPickModal = ({ originBrandPick, isShow, onClose, onClick }: IBrandPickModalProps): React.ReactElement => {
  const { userInfo } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [searchTitle, setSearchTitle] = useState('');
  const [pickList, setPickList] = useState([]);
  const [selectedPickList, setSelectedPickList] = useState([]);
  const [unSelectedPickList, setUnSelectedPickList] = useState([]);
  const [isKeyDown, setIsKeyDown] = useState(false);

  const {
    data: unSelectedData,
    loading: loadingUnSelected,
    refetch: refetchUnSelected,
  } = useBrandAssetsQuery({
    variables: { title: searchTitle },
    skip: !Boolean(userInfo?.brandId),
  });

  const handleOnSearchTextFiled = _.debounce((e) => {
    setSearchTitle(e.target.value);
    setIsKeyDown(false);
  }, 500);

  const handleOnToggleCheckbox = (e) => {
    if (selectedPickList.length >= 4 && e.target.checked) {
      alert(t('BrandPage.brandPick.maximumDescription'));
      return;
    }
    const brandId = e.target.id;
    const newPickList = _.map(pickList, (list) => {
      if (String(list.id) === String(brandId)) list.isChecked = e.target.checked;
      return list;
    });
    setPickList(newPickList);
    setSelectedPickList(_.filter(newPickList, (list) => list.isChecked));
  };

  const handleOnClose = () => {
    handleOnClear();
    onClose();
  };
  const handleOnClear = () => {};

  useEffect(() => {
    const copyBrandPick = _.cloneDeep(originBrandPick) as IPickList[];
    copyBrandPick.forEach((assets) => (assets.isChecked = true));
    setSelectedPickList(copyBrandPick);
  }, [originBrandPick]);

  useEffect(() => {
    if (unSelectedData) {
      const newBrandAssets = _.cloneDeep(unSelectedData.brandAssets) as IPickList[];
      const newUnSelectedPickList = _.filter(newBrandAssets, (assets) => !_.map(originBrandPick, (item: any) => item.id).includes(assets.id));
      newUnSelectedPickList.forEach((assets) => (assets.isChecked = false));
      setUnSelectedPickList(newUnSelectedPickList);
    }
  }, [originBrandPick, unSelectedData]);

  useEffect(() => {
    isShow && refetchUnSelected({ title: '' });
  }, [isShow, refetchUnSelected]);

  useEffect(() => {
    if (isShow) {
      const copyBrandPick = _.cloneDeep(originBrandPick) as IPickList[];
      copyBrandPick.forEach((assets) => (assets.isChecked = true));

      setPickList(_.concat(copyBrandPick, unSelectedPickList));
    }
  }, [isShow, originBrandPick, unSelectedPickList]);

  const isKeyUpLoading = loadingUnSelected || isKeyDown;

  return (
    <BrandPickModalContainer centered show={isShow} onHide={handleOnClose}>
      <BrandPickModalBody>
        <TextField
          onKeyUp={handleOnSearchTextFiled}
          onKeyDown={() => setIsKeyDown(true)}
          disabled={false}
          InputProps={{ startAdornment: <SearchIcon style={{ marginRight: '8px' }} /> }}
          placeholder={t('BrandPage.brandPick.maxlength')}
          fullWidth
          inputProps={{ maxLength: 30 }}
          size="small"
        />

        {isKeyUpLoading ? (
          <BrandPickModalLoading />
        ) : (
          <BrandPickModalList>
            {!pickList.length ? (
              <BrandPickModalListItem>
                <BrandPickListItemText>검색 결과가 없습니다.</BrandPickListItemText>
              </BrandPickModalListItem>
            ) : (
              <Fragment>
                {pickList.map((asset) => {
                  const title = _.find(asset.i18ns, (item) => item.language === i18n.language.toUpperCase())?.title;
                  return (
                    <BrandPickModalListItem key={asset.id}>
                      <Checkbox sx={{ padding: 0 }} onChange={handleOnToggleCheckbox} checked={asset.isChecked} id={String(asset.id)} />
                      <BrandPickListItemText>{asset.id}</BrandPickListItemText>
                      <BrandPickListItemText>{title}</BrandPickListItemText>
                    </BrandPickModalListItem>
                  );
                })}
              </Fragment>
            )}
          </BrandPickModalList>
        )}

        <BrandPickModalSaveWrap>
          <Button
            startIcon={<CircleCheckedIcon style={{ marginRight: 10 }} />}
            colorTheme="primary"
            onClick={() => onClick(selectedPickList)}
            disabled={false}
            minWidth="132px"
            width="fit-content"
            marginLeft="12px"
            borderRadius="4px"
          >
            선택 완료
          </Button>
        </BrandPickModalSaveWrap>
      </BrandPickModalBody>
    </BrandPickModalContainer>
  );
};

export default BrandPickModal;
