import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const Wrap = styled(Modal)`
  div.modal-dialog {
    font-family: "Noto Sans KR", sans-serif !important;
    line-height: 20px !important;
    position: absolute;
    top: calc(50% - 240px);
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
`;

export const Flex = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const ErrorMessage = styled.div`
  color: red;
  padding-left: 5px;
  padding-top: 5px;
  display:${(props) => (props.show ? "block" : "none")};
`;

export const List = styled.div`
    max-height: 236px;
    overflow-y: auto;
    border-top: 1px solid #E0E0E0;
    margin-top: 16px;
    font-weight: 400;
`;

export const ListItem = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px; 
    border-bottom: 1px solid #E0E0E0;
    min-height: 40px;
    padding-top: 13px;
    padding-bottom: 13px;
`;

export const NameField = styled.div`
    font-size: 12px;
    width: 60%;
    margin-top: 5px;
    line-height: 100%;
    padding-right: 10px;
    word-break: break-word;
`;

export const DateField = styled.div`
    font-size: 12px;
    width: 20%;
    line-height: 100%;
`;
export const Button = styled.div`
    background-color: none;
    cursor: pointer;
`;