import { BlackButton, WhiteButton } from 'components/button';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 1060px;
    margin: 0 auto;
`;
export const Top = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 26px;
`;
export const Table = styled.div`
`;
export const Row = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid #aaa;
    padding-top: 30px;
    padding-bottom: 30px;

    ${props => props.isHead ? `
        font-size: 14px;
        font-weight: 700;
        border-bottom: 2px solid #333;
    ` : ``}
`;
export const Column = styled.div`
    ${props => props.flex ? `flex: ${props.flex};` : ``}
    ${props => props.align ? `text-align: ${props.align};` : ''}
`;

export const ColumnItem = styled.div``;

export const ColumnLink = styled.a`
    display: flex;
    color: #333;
    &: hover {
        color: inherit;
    }
    ${props => props.flex ? `flex: ${props.flex};` : ``}
`;

export const ButtonWrap = styled.div`
    align-items: center;
    display: flex;

    button {
        margin-left: 5px;
    }
`;
export const WriteButton = styled(BlackButton)`
    width: 187px;
    height: 40px;
    & > a { 
        color: inherit;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    & > a:hover {
        color: inherit;
    }
`;

export const RemoveButton = styled(WhiteButton)`
    width: 109px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

    &:disabled {
        opacity: 0.5;
    }
    & > a { 
        color: black;
    }
    & > a:hover {
        color: inherit;
    }
`;

export const NaviWrapper = styled.div`
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 15px 0px;
`;