import React, { useEffect, useState } from "react";
import { Radio } from "../input";
import { BusinessTypeEnum, SignupChapterEnum, UserTypeEnum } from "../type";
import { useRecoilState } from "recoil";
import { chapterState, userTypeFormState } from "signup/SignUp.atoms";
import { Box, Stack, Typography } from "@mui/material";
import Navigator from "signup/Navigator";

const UserTypes = [
  {
    label: "개인",
    value: UserTypeEnum.Personal,
  },
  {
    label: "사업자",
    value: UserTypeEnum.Business,
  },
];

const BusinessTypes = [
  {
    label: "과세 사업자",
    value: BusinessTypeEnum.Taxable,
  },
  {
    label: "면세 사업자",
    value: BusinessTypeEnum.DutyFree,
  },
  {
    label: "과면세 사업자",
    value: BusinessTypeEnum.TaxExempt,
  },
  {
    label: "간이 사업자",
    value: BusinessTypeEnum.SimpleBusiness,
  },
];

export default function UserTypeForm() {
  const [userTypeForm, setUserTypeForm] = useRecoilState(userTypeFormState);

  const [, setChapterValid] = useRecoilState(chapterState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserTypeForm({
      userType: e.target.value as UserTypeEnum,
      businessType: undefined,
    });
  };

  const handleBusinessChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserTypeForm({
      ...userTypeForm,
      businessType: e.target.value as BusinessTypeEnum,
    });
  };

  const setCurrentChapterValid = (valid: boolean) => {
    setChapterValid((prev) => {
      return {
        ...prev,
        [SignupChapterEnum.UserType]: valid,
      };
    });
  };

  useEffect(() => {
    if (!userTypeForm.userType && !userTypeForm.businessType) return;

    if (
      userTypeForm.userType === UserTypeEnum.Personal &&
      !userTypeForm.businessType
    ) {
      setCurrentChapterValid(false);
    }

    setCurrentChapterValid(true);
  }, [userTypeForm.userType, userTypeForm.businessType]);

  const handleNext: () => Promise<string> = () => {
    return new Promise((resolve) => {
      resolve("");
    });
  };

  return (
    <>
      <Box>
        <Stack spacing={10}>
          <Stack spacing={7.5} alignItems={"center"}>
            <Typography
              fontWeight={700}
              fontSize={"22px"}
              lineHeight={"150%"}
              color={"#333"}
            >
              에이콘3D의 입점을 환영합니다! <br /> 가입 유형을 알려주세요.
            </Typography>
            <Stack spacing={3}>
              {UserTypes.map((x, i) => (
                <Radio
                  key={`user_${i}`}
                  name="type"
                  value={x.value}
                  checkValue={userTypeForm.userType}
                  onChange={handleChange}
                >
                  {x.label}
                </Radio>
              ))}
            </Stack>
          </Stack>
          {userTypeForm.userType === UserTypeEnum.Business && (
            <Stack spacing={7.5} alignItems={"center"}>
              <Typography fontWeight={700}>
                사업자 유형을 선택해주세요.
              </Typography>
              <Stack spacing={3}>
                {BusinessTypes.map((x, i) => (
                  <Radio
                    key={`business_${i}`}
                    name="subType"
                    value={x.value}
                    checkValue={userTypeForm.businessType}
                    onChange={handleBusinessChange}
                  >
                    {x.label}
                  </Radio>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>
      <Navigator onNext={handleNext} />
    </>
  );
}
