import { atom } from "recoil";
import { LANG_CODE } from "../constants";
import { DocumentDataType } from "./type";

export const originalDocumentData = atom<DocumentDataType>({
  key: 'originalDocumentData',
  default: {
    language: LANG_CODE.KO,
    originalLanguage: LANG_CODE.KO,
    file: {
      filePath: undefined,
      fileName: undefined,
      fileComponents: undefined,
      fileUpdates: undefined,
    },
    mainImage: {
      path: undefined,
      name: undefined,
      cropInfo: {
        imageOriginX: undefined,
        imageOriginY: undefined,
        imageWidth: undefined,
        imageHeight: undefined,
      },
      isComplete: false,
    },
    subImage: {
      path: undefined,
      name: undefined,
      cropInfo: {
        imageOriginX: undefined,
        imageOriginY: undefined,
        imageWidth: undefined,
        imageHeight: undefined,
      },
      isComplete: false,
    },
    categories: {
      genre: {
        categoryBranch: undefined,
      },
      theme: {
        categoryBranch: undefined,
      },
      game: {
        categoryBranch: undefined,
      }
    },
    keywords: [],
    copyright: {
      isOriginal: true,
      commercialSources: undefined,
      warehouseSources: undefined,
      hasRealLogo: undefined,
      isErrorWarehouseCopyright: false,
      isErrorFreeCopyright: false,
      isErrorAgree: false,
    },
    isAdultOnly: false,
    webtoon: false,
    game: false,
  },
});