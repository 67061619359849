import React, { useState, useRef, useEffect } from 'react';
import { Wrap, DragAndDrop, CroppperBox } from './style';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { uploadFile } from 'api';
import { Loading } from 'boards/DetailBoardWrite/style';
import { Box } from '@mui/material';
import { File, FileUpload } from 'acon-mui/components/Board';
import { Button } from 'acon-mui/components';
import { ReactComponent as Download } from 'boards/DetailBoardWrite/assets/download.svg';
import { ReactComponent as ImageIcon } from 'boards/DetailBoardWrite/assets/image-upload.svg';
import { ReactComponent as DoubleCheck } from 'boards/DetailBoardWrite/assets/double-check.svg';
import { ReactComponent as Rewrite } from 'boards/DetailBoardWrite/assets/rewrite.svg';
import downloadImg from 'utils/downloadImg';
import { CropInfo } from 'boards/DetailBoardWrite/recoil/type';

type Props = {
  isUploadCropImg?: boolean;
  minWidth: number;
  minHeight: number;
  boxWidth: number;
  boxHeight: number;
  targetWidth: number;
  targetHeight: number;

  // 크롭된 이미지 블롭 설정하기
  croppedImageBlob?: string;
  setCroppedImageBlob: (val: string) => void;
  // 크롭된 이미지 URL 설정하기
  croppedImageDataUrl?: string;
  setCroppedImageDataUrl: (val: string) => void;

  // 크롭된 정보들
  setCropInfo: (val: CropInfo | null) => void;
  width: number | string;
  left: number | string;
  height: number | string;
  top: number | string;

  // 크로퍼 태그
  cropperTag?: any;

  // 비활성화 여부
  disabled?: boolean;

  isComplete: boolean;
  // 완료여부
  setComplete: (val: boolean) => void;

  // 파일 설정 시 콜백함수
  setCallback: () => void;
  setFileCallback: () => void;
  setCompleteCallback: (val: boolean) => void;

  // 표시여부
  isShow?: boolean;

  name?: string;
  // 표시 할 이미지 URL
  url: string;
  // 이미지 URL 설정하기
  setImageUrl: (val1: string, val2: string) => void;

  // 표시할 이미지
  image?: string;
  // 표시할 이미지 설정
  setImage?: (val: string) => void;
  // 모든 이미지 이벤트 핸들러
  allImageEventHandler?: any;

  // 허용 확장자
  extensions: string[];
  // 파일 크기
  sizeLimit: number;
  // 파일 개수 제한
  limit: number;
  // 크롭 비율
  cropRatio: number;

  showMessage?: (text: string, state?: boolean) => void;
  onDeleteImage?: () => void;
};

export default ({
  isUploadCropImg,
  minWidth,
  minHeight,
  boxWidth,
  boxHeight,
  targetWidth,
  targetHeight,

  // 크롭된 이미지 블롭 설정하기
  setCroppedImageBlob,
  // 크롭된 이미지 URL 설정하기
  setCroppedImageDataUrl,

  // 크롭된 정보들
  setCropInfo,
  width,
  left,
  height,
  top,

  // 크로퍼 태그
  cropperTag,

  // 비활성화 여부
  disabled,

  isComplete,
  // 완료여부
  setComplete,

  // 파일 설정 시 콜백함수
  setCallback,
  setFileCallback,
  setCompleteCallback,

  // 표시여부
  isShow,

  // 표시 할 이미지 이름
  name,
  // 표시 할 이미지 URL
  url,
  // 이미지 URL 설정하기
  setImageUrl,

  // 표시할 이미지
  image,
  // 표시할 이미지 설정
  setImage,
  // 모든 이미지 이벤트 핸들러
  allImageEventHandler,

  // 허용 확장자
  extensions,
  // 파일 크기
  sizeLimit,
  // 파일 개수 제한
  limit,
  // 크롭 비율
  cropRatio,

  showMessage,
  onDeleteImage,
}: Props) => {
  // 번역도구
  const { t } = useTranslation();

  // 로딩 여부
  const [isLoading, setIsLoading] = useState(false);
  // 드래그 여부
  const [isDrag, setIsDrag] = useState(false);
  // 파일 태그에 대한 ref
  const fileTag = useRef(null);

  useEffect(() => {
    if (url) {
      // 이미지 정보 설정
      if (/^data:image/g.exec(url)) {
        setImage(url);
      } else {
        setImage(`${process.env.STORAGE_ORIGIN}/${url.replace(`${process.env.STORAGE_ORIGIN}/`, '')}`.replace(/(https?:\/\/)|(\/)+/g, '$1$2'));
      }
      // 종료
      return;
    }
  }, [url]);

  const onReady = () => {
    // 이미지 URl 이 존재하지 않을경우

    if (!url)
      // 종료
      return;

    // 크롭 데이터가 하나라도 존재할 경우
    if (width || height || left || top || isComplete) {
      const cropper = cropperTag.current.cropper;

      // 부모에서 넘어온 크롭 데이터
      const parentCropData = { width, height, left, top };
      // 크롭박스 위치 설정
      cropper.setCropBoxData(parentCropData);
      // 크로퍼 비활성화
      cropper.disable();

      const canvas = cropper.getCroppedCanvas();
      // 이미지 설정
      canvas.toBlob((blob) => {
        setCroppedImageBlob && setCroppedImageBlob(blob);
        setCroppedImageDataUrl && setCroppedImageDataUrl(canvas.toDataURL('image/jpeg', 1.0));
        // 완료된 경우, 완료처리
        setComplete && setComplete(true);
        setCompleteCallback && setCompleteCallback(true);
      });
    }
  };

  // 파일 결과를 가져오는 메소드입니다.
  const getFileResult = async (file) => {
    return new Promise((resolve) => {
      var fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = () => {
        resolve(fr.result);
      };
    });
  };

  // 파일 크기를 가져오는 메소드입니다.,
  const getFileSize = async (blob) => {
    return new Promise((resolve) => {
      const imgTag = document.createElement('img');
      imgTag.src = blob;
      imgTag.onload = function () {
        resolve({
          width: imgTag.width,
          height: imgTag.height,
        });
      };
    });
  };

  // 파일 설정하기 메소드
  const setFile = async (file) => {
    // 로딩창 표시
    setIsLoading(true);

    // 파일 크기가 제한된 것보다 클 경우
    if (file.size / (1024 * 1024) > sizeLimit) {
      // 메세지 표시
      showMessage(t('pleaseFileLimit'));

      if (fileTag && fileTag.current && fileTag.current.value) fileTag.current.value = null;

      setIsLoading(false);
      // 종료
      return;
    }
    // 설정한 확장자가 아닐경우
    if (extensions.indexOf(file.type) === -1) {
      // 메세지 표시
      showMessage(t('pleaseCheckExtension'));

      if (fileTag && fileTag.current && fileTag.current.value) fileTag.current.value = null;

      setIsLoading(false);
      // 종료
      return;
    }
    setCallback && setCallback();
    // 파일결과
    const fileResult: string | any = await getFileResult(file);
    // 파일 크기 객체
    const fileSizeObj: { width?: number; height?: number } | any = await getFileSize(fileResult);

    // 파일 크기가 올바르지 않은경우
    if (minWidth > fileSizeObj?.width || minHeight > fileSizeObj?.height) {
      // 메세지 표시
      showMessage(t('pleaseCheckImageSize'));

      if (fileTag && fileTag.current && fileTag.current.value) fileTag.current.value = null;

      setIsLoading(false);
      // 종료
      return;
    }
    cropperTag && cropperTag.current && cropperTag.current.cropper && cropperTag.current.cropper.enable && cropperTag.current.cropper.enable();
    // 파일 URL
    const { key } = await uploadFile(file, true);

    // 이미지 URL 설정
    setImageUrl(key, key.split('/').pop());
    // 이미지 설정
    setImage(fileResult);

    // 드래그 해제
    setIsDrag(false);
    // 크롭정보 초기화
    setCropInfo(null);
    // 완료여부 false
    setComplete(false);

    // 파일 설정 시 콜백함수
    setFileCallback && setFileCallback();

    // 로딩창 종료
    setIsLoading(false);
    return 'success';
  };
  // 파일들 설정하기 메소드
  const setFiles = async (files) => {
    // 모든 이미지의 대한 이벤트 핸들러가 지정이 되지 않았을 경우, 파일들 설정하기 메소드를 실행하지 않는다.
    if (!allImageEventHandler) {
      // 종료
      return;
    }
    setIsLoading(true);
    try {
      // 파일 루프
      for (let i = 0; i < files.length; i++) {
        // 이벤트 핸들러
        const eventHandler = allImageEventHandler[i];
        // 현재 파일
        const file = files[i];
        // 파일 크기가 제한된 것보다 클 경우
        if (file.size / (1024 * 1024) > sizeLimit) {
          // 메세지 표시
          showMessage(t('pleaseFileLimit'));

          if (fileTag && fileTag.current && fileTag.current.value) fileTag.current.value = null;

          // 종료
          return;
        }
        // 설정한 확장자가 아닐경우
        if (extensions.indexOf(file.type) === -1) {
          // 메세지 표시
          showMessage(t('pleaseCheckExtension'));

          if (fileTag && fileTag.current && fileTag.current.value) fileTag.current.value = null;

          // 종료
          return;
        }
        setCallback();
        // 파일결과
        const fileResult: string | any = await getFileResult(file);
        // 파일 크기 객체
        const fileSizeObj: { width?: number; height?: number } | any = await getFileSize(fileResult);

        // 파일 크기가 올바르지 않은경우
        if (minWidth > fileSizeObj.width || minHeight > fileSizeObj.height) {
          // 메세지 표시
          showMessage(t('pleaseCheckImageSize'));

          if (fileTag && fileTag.current && fileTag.current.value) fileTag.current.value = null;

          // 종료
          return;
        }
        eventHandler.cropperTag &&
          eventHandler.cropperTag.current &&
          eventHandler.cropperTag.current.cropper &&
          eventHandler.cropperTag.current.cropper.enable &&
          eventHandler.cropperTag.current.cropper.enable();

        // 파일 URL
        const { key } = await uploadFile(file, true);

        // 이미지 URL 설정
        eventHandler.setImageUrl(key, key.split('/').pop());
        // 이미지 설정
        eventHandler.setImage(fileResult);

        // 드래그 해제
        setIsDrag(false);
        // 크롭정보 초기화
        eventHandler.setCropInfo({
          imageOriginX: '',
          imageOriginY: '',
          imageWidth: '',
          imageHeight: '',
        });
        // 완료여부 false
        eventHandler.setComplete(false);

        // 파일 설정 시 콜백함수
        setFileCallback && setFileCallback();
        return 'success';
      }
      allImageEventHandler[0].setImageType();
    } catch (errObj) {
      // 실패 메세지
      errObj.message = `setFiles 실행 도중, 예상치 못한 예외 발생(${errObj.message})`;
      // 실패 로그 기록(TODO)
      console.log(errObj);
    } finally {
      // 로딩창 제거
      setIsLoading(false);
    }
  };

  const onDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // 파일들
    const files = e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files;

    // 파일이 존재하지 않을경우
    if (!files)
      // 종료
      return;

    // 제한된 길이보다 파일 개수가 많은경우
    if (files.length > limit) {
      // 메세지 표시
      showMessage(t('pleaseCheckLimit'));
      // 종료
      return;
    }

    // 파일 길이
    if (files.length === 1) {
      // 1개의 파일에 대한 파일 설정
      await setFile(files[0]);
      // 종료
      return;
    }

    // 파일이 1개 초과이고, 제한된 파일 길이 내로 업로드 한 경우
    if (files.length > 1 && files.length <= limit) {
      // 파일 설정하기
      await setFiles(files);
      // 종료
      return;
    }
  };
  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // 드래그 해제
    setIsDrag(false);
  };
  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // 항목이 존재할 경우
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      // 드래그 중
      setIsDrag(true);
    }
  };

  const onChangeFileTag = async (e) => {
    // 파일
    const files = e.target.files;

    // 제한된 길이보다 파일 개수가 많은경우
    if (files.length > limit) {
      // 메세지 표시
      showMessage(t('pleaseCheckLimit'));
      // 종료
      return;
    }

    // 파일 길이
    if (files.length === 1) {
      // 1개의 파일에 대한 파일 설정
      await setFile(files[0]);
      // 종료
      return;
    }

    // 파일이 1개 초과이고, 제한된 파일 길이 내로 업로드 한 경우
    if (files.length > 1 && files.length <= limit) {
      // 파일 설정하기
      await setFiles(files);
      // 종료
      return;
    }
  };

  const onClickForFileChange = () => {
    // 파일 태그가 존재할 경우, 클릭
    fileTag && fileTag.current && fileTag.current.click();
  };

  // 저장 버튼 태그 클릭 이벤트 처리기 메소드
  const onClickSaveButtonTag = async () => {
    let option;
    if (!isUploadCropImg) {
      option = {
        width: targetWidth * 2,
        height: targetHeight * 2,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      };
    }

    const canvas = cropperTag.current.cropper.getCroppedCanvas(option);
    // 크롭된 이미지 정보
    canvas.toBlob(async (blob) => {
      // 크로퍼 비활성화
      cropperTag.current.cropper.disable();
      setCroppedImageBlob && setCroppedImageBlob(blob);
      setCroppedImageDataUrl && setCroppedImageDataUrl(canvas.toDataURL('image/jpeg', 1.0));

      // 크롭 정보 저장
      // left (x) / top (y) / width / height
      const cropInfoObj = { ...cropperTag.current.cropper.getCropBoxData() };

      // 크롭 이미지 업로드를 받는경우
      if (isUploadCropImg) {
        // 크롭된 이미지 업로드
        const { key } = await uploadFile(blob, true);
        // 크롭된 이미지 키 설정
        cropInfoObj.key = key;
      }
      if ((await setFile(blob)) === 'success') {
        // 크롭정보 설정
        setCropInfo({
          imageOriginX: undefined,
          imageOriginY: undefined,
          imageWidth: undefined,
          imageHeight: undefined,
        });

        // 완료된 경우, 완료처리
        setComplete && setComplete(true);
        setCompleteCallback && setCompleteCallback(true);
        // 메세지 표시
        showMessage(t('saved'), true);
      }
    });
  };
  // 다시 자르기 버튼 태그 클릭 이벤트 처리기 메소드
  const onClickRetryCropButtonTag = () => {
    cropperTag.current.cropper.enable();
    // 크롭된 이미지 blob 초기화
    setCroppedImageBlob && setCroppedImageBlob('');
    // 크롭된 이미지 dataurl 초기화
    setCroppedImageDataUrl && setCroppedImageDataUrl('');
    setCropInfo(null);
    setComplete(false);
  };

  return (
    <>
      <Loading show={isLoading} />
      <Wrap active={isShow}>
        {!image && <DragAndDrop onClick={onClickForFileChange} onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave} onDragEnter={onDragEnter} />}
        {image && (
          <CroppperBox drag={isDrag} crop={image}>
            <Cropper
              crossOrigin="anonymous"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
              {...(isComplete && { className: 'cropper-disabled' })}
              minContainerWidth={0}
              minContainerHeight={0}
              viewMode={1}
              src={image}
              movable={false}
              zoomable={false}
              ready={onReady}
              minCropBoxWidth={isComplete ? boxWidth : boxWidth * 0.5}
              minCropBoxHeight={isComplete ? boxHeight : boxHeight * 0.5}
              wheelZoomRatio={cropRatio}
              aspectRatio={cropRatio}
              initialAspectRatio={cropRatio}
              guides={false}
              ref={cropperTag}
            />
          </CroppperBox>
        )}
        <Box {...(image && { display: 'none' })}>
          <FileUpload onChange={onChangeFileTag} ref={fileTag} accept="image/jpeg, image/png, image/jpg" />
        </Box>
        {image && !disabled && (
          <Box mt={1} display="flex">
            <Button 
              onClick={() => downloadImg(image, name)} 
              startIcon={<Download style={{ marginRight: '8px' }} />} 
              marginRight="8px" 
              fontWeight="700"
              {...!isComplete && { disabled: true }}
            >
              {t('document.image.download')}
            </Button>
            <Button onClick={onClickForFileChange} startIcon={<ImageIcon style={{ marginRight: '8px' }} />} marginRight="8px" fontWeight="700">
              {t('document.image.change')}
            </Button>
            {isComplete && (
              <Button variant="contained" onClick={onClickRetryCropButtonTag} startIcon={<Rewrite style={{ marginRight: '8px' }} />} fontWeight="700">
                {t('document.image.retry')}
              </Button>
            )}
            {!isComplete && (
              <Button
                colorTheme="primary"
                variant="contained"
                onClick={onClickSaveButtonTag}
                startIcon={<DoubleCheck style={{ marginRight: '8px' }} />}
                fontWeight="700"
                boxShadow="none"
              >
                {t('document.image.save')}
              </Button>
            )}
          </Box>
        )}
        {image && (
          <File
            fileName={name || url}
            status={name || url ? 'success' : 'inprogress'}
            progress={name || url ? 100 : 0}
            fileSize={fileTag.current?.files[0]?.size || null}
            onDelete={() => {
              onDeleteImage && onDeleteImage();
              fileTag.current.value = '';
            }}
            marginTop="24px"
          />
        )}
      </Wrap>
    </>
  );
};
