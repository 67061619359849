import styled from 'styled-components';

const Button = styled.button`
    width: 192px;
    height: 48px;

    background: #FFFFFF;
    border: 1px solid #7C7C7C;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    font-weight: 500;
    font-size: 14px;

    opacity: ${(props) => props.disabled ? '0.5' : '1'};
    margin-right: 10px;
`;

const GrayButton = styled(Button)`
    background: #333333;
    color: #FFFFFF;
`;

const ShortButton = styled(Button)`
    width: 100px;
    margin: 0;
`;


export default Button;
export {
    GrayButton,
    ShortButton
}