import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker, KeyboardDatePicker, TimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import clock from '../../img/picker_icon_clock.svg'

const DatePickerInput = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker {...props}/>
    </MuiPickersUtilsProvider>
  );
}

const KeyboardDatePickerInput = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker {...props}/>
    </MuiPickersUtilsProvider>
  );
}

const TimePickerInput = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker {...props}/>
    </MuiPickersUtilsProvider>
  );
}

const ClockIcon = <img width="15px" src={clock} />

const KeyboardTimePickerInput = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker keyboardIcon={ClockIcon} {...props}/>
    </MuiPickersUtilsProvider>
  );
}

export { DatePickerInput, KeyboardDatePickerInput, TimePickerInput, KeyboardTimePickerInput }