import { authOrigin } from "api";
import axios from "axios";

export const isLogin = async () => {
  const result = {};
  try {
    let data = await fetch(authOrigin + "/auth/info", {
      credentials: "include",
    }).then(function (response) {
      return response.json();
    });

    result._id = data.id;
    result.brandId = data.brandId;
    result.id = data.account;
    result.name = data.name;
    result.isAdmin = data.isAdmin;
    result.isAccept = data.isAccept;
    result.isFirst = data.isFirst;
    result.isUpdateTerm = data.isUpdateTerm;
    result.isCompany = data.isCompany;
    result.hasntPlan = data.hasntPlan;
    result.acceptStatus = data.acceptStatus;

    console.log(result);
  } catch (err) {
    console.log(err);
  }

  return result;
};

const AUTH_URL_SIGNIN = authOrigin + "/auth/signin";

const Signin = async (account, password) => {
  const data = {
    account,
    password,
  };

  const options = {
    withCredentials: true,
  };

  let result = false;

  try {
    result = await axios.post(AUTH_URL_SIGNIN, data, options);
    console.log(result);
    if (result.status !== 200) {
      throw "status is not 200 on request of signin";
    }

    result = result.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  } finally {
    return result;
  }
};

const resign = async () => {
  const options = {
    withCredentials: true,
  };
  try {
    const result = await axios.post(authOrigin + "/auth/resign", null, options);

    if (result.status !== 200) {
      throw "status is not 200 on request of signin";
    }
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export { Signin, resign };
