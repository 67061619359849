import React from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  ~ div {
    margin-top: 20px;
  }
  ${(props) => props.align && `align-items: ${props.align};`}
  ${(props) => props.justify && `justify-content: ${props.justify};`}
`;

export const FlexChild = styled.div`
  ${(props) => (props.flex ? `flex: ${props.flex};` : "")}
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : "")}
`;

export const Wrap = styled.div`
  font-size: 16px;
`;
export const Row = styled.div`
  width: 100%;
  float: left;
  padding: 10px 0px;
`;
export const HalfCol = styled.div`
  width: 50%;
  float: left;
`;
export const LayoutBox = styled.div`
  padding: 25px;
  width: calc(100% - 20px);
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  float: left;
  ${(props) => (props.isHide ? "display: none;" : "")}
`;
export const TreeView = styled.div`
  width: 100%;
`;
export const TreeInfoView = styled.div`
  width: 100%;
  padding: 25px;
`;
export const Left = styled.div`
  float: left;
`;
export const Right = styled.div`
  float: right;
`;
export const Select = styled.select`
  width: 125px;
  height: 36px;
  margin-right: 18px;
`;
export const Option = styled.option``;
export const FieldTitle = styled.span`
  padding-left: 10px;
  font-size: 18px;
  color: #333;
  ${(props) => {
    if (props.required) {
      return `&::after {
                content: '*';
                color: red;
            }`;
    }
  }}
`;
export const FieldInput = styled.input.attrs({
  type: "text",
})`
  width: 100%;
  border: 1px solid #aaa;
  height: 36px;
  padding: 15px;
  font-size: 16px;
`;

export const FieldTextarea = styled.textarea`
  width: 100%;
  min-height: 100px;
  border: 1px solid #aaa;
  height: 36px;
  padding: 15px;
  font-size: 16px;
`;

export const Field = styled.div`
  width: 100%;
  margin-bottom: 15px;
  float: left;
`;

export const CheckboxItem = ({ id, name, text, value, checked, onChange }) => {
  const Item = styled.div`
    float: left;
    margin-right: 20px;
  `;
  const HtmlForLabel = styled.label`
    margin-left: 7px;
  `;
  return (
    <Item className="noDrag">
      <HtmlForLabel htmlFor={id}>
        <input
          type="checkbox"
          name={name}
          checked={checked}
          value={value}
          onChange={onChange}
        />
        {text}
      </HtmlForLabel>
    </Item>
  );
};
export const Input = styled.input`
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
`;
export const BtnDropdown = styled(Dropdown)`
  ${(props) => (!props.isshow ? "display: none;" : "")}
`;
export const BtnDropdownItem = styled(Dropdown.Item)`
  width: 100%;
  float: left;
  text-align: center;
  color: #666;
`;
export const TreeBtn = styled.button.attrs({
  type: "button",
})`
  font-size: 14px;
  margin-bottom: 5px;
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 3px;
  display: inline-block;
  float: right;

  &:hover {
    background-color: #ccc;
  }
`;
