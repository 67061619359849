import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app';
import { useTranslation } from 'react-i18next';
import Search from './search';
import QueryString from 'query-string';
import { FlexColWrap, Page, Title, Wrap } from './style';
import { GET_BRANDS, GET_SETTLE, GET_SETTLE_ALL, GET_SETTLE_PRICE } from '../../api/quries';
import { useLazyQuery, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { displayMoneyByLang, isKorean } from '../../lib/multiLocale';
import Filter from './filter';
import { NoticePopup, ProductPopup, SettlePopup } from './popup';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { color } from 'acon-mui/style';
import { isMobile } from 'react-device-detect';
import { Button, Container, SelectBox, PageNavigation, DateRangePicker } from 'acon-mui/components';
import { Field, Table as ExcelTable, TBody, Td, Th, THead, Tr } from '../../components/table';
import { ReactComponent as DownloadIcon } from 'acon-mui/icons/icon-download.svg';
import { ReactComponent as ChevronIcon } from 'acon-mui/icons/icon-chevron.svg';
import { ReactComponent as ArrowIcon } from 'acon-mui/icons/icon-arrow-down.svg';
import AconHelper from '../../lib/global';
import palette from 'theme/palette';
import { useGetSettleListQuery } from 'generated/graphql';

const endDateObj = new Date();
const startDateObj = dayjs(endDateObj).subtract(1, 'month').add(1, 'day');
const viewCountList = [
  { id: 1, label: '20' },
  { id: 2, label: '50' },
  { id: 3, label: '100' },
  { id: 4, label: '200' },
];

//msSaveBlob deprecate 된 문제 해결
declare global {
  interface Navigator {
    msSaveBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}

export default (props) => {
  // 정산 모달 표시여부
  const [isShowSettleModal, setIsShowSettleModal] = useState(false);
  // 상품 모달 표시여부
  const [isShowProductModal, setIsShowProductModal] = useState(false);
  // 공지 모달 표시여부
  const [isShowNoticeModal, setIsShowNoticeModal] = useState(false);

  // 배경색 변경하기 메소드, 사용자 정보
  const { setBackgroundColor, userInfo } = useContext(AppContext);

  // 브랜드 ID
  const brandId = userInfo && userInfo.brandId ? userInfo.brandId : 0;

  // 일자 검색 유형
  const [dateSearchType, setDateSearchType] = useState('payment');
  // 시작일자
  const [startDate, setStartDate] = useState(sessionStorage.getItem('startdate') ? sessionStorage.getItem('startdate') : `${dayjs(startDateObj).format('YYYY-MM-DD')} 00:00:00`);
  // 종료일자
  const [endDate, setEndDate] = useState(sessionStorage.getItem('enddate') ? sessionStorage.getItem('enddate') : `${dayjs(endDateObj).format('YYYY-MM-DD')} 23:59:59`);
  // 결제일지 / 주문일시 필터 표시 여부
  const [isShowFilter, setIsShowFilter] = useState(false);
  // 선택된 필터 값
  const [selectedFilterValue, setSelectedFilterValue] = useState(localStorage.getItem('settle_filter_payment') ? localStorage.getItem('settle_filter_payment') : 'payment');
  const { history, match } = props;
  // 전체 읽기 권한 여부
  const isReadAll = userInfo.isAdmin;
  // url 매개변수 객체
  let urlParamObj = (() => {
    let obj: {
      brandId: string;
      page: number;
      limit: number;
      goodsNm: string | string[];
      penNm: string | string[];
      startDate: string | string[];
      endDate: string | string[];
      paymentStartDate: string | string[];
      paymentEndDate: string | string[];
      settleStartDate: string | string[];
      settleEndDate: string | string[];
    } = {
      brandId: brandId.toString(),
      // 상품명
      goodsNm: '',
      // 필명
      penNm: '',
      startDate: dateSearchType === 'order' ? startDate : '',
      endDate: dateSearchType === 'order' ? endDate : '',
      paymentStartDate: dateSearchType === 'payment' ? startDate : '',
      paymentEndDate: dateSearchType === 'payment' ? endDate : '',
      settleStartDate: dateSearchType === 'settle' ? startDate : '',
      settleEndDate: dateSearchType === 'settle' ? endDate : '',
      // 페이지
      page: 1,
      // 개수제한
      limit: sessionStorage.getItem('settleview') ? Number(sessionStorage.getItem('settleview')) : 20,
    };

    // search 값이 존재할 경우
    if (history?.location?.search) {
      // url 파라메터 객체
      const urlParamObj = QueryString.parse(history.location.search);

      obj.brandId = urlParamObj.brandId ? urlParamObj.brandId : brandId.toString();
      obj.page = Number(urlParamObj.page) ? Number(urlParamObj.page) : obj.page;
      obj.limit = Number(urlParamObj.limit) ? Number(urlParamObj.limit) : obj.limit;
      obj.goodsNm = urlParamObj.goodsNm ? urlParamObj.goodsNm : obj.goodsNm;
      obj.penNm = urlParamObj.penNm ? urlParamObj.penNm : obj.penNm;
      obj.startDate = urlParamObj.startDate ? urlParamObj.startDate : obj.startDate;
      obj.endDate = urlParamObj.endDate ? urlParamObj.endDate : obj.endDate;
      obj.paymentStartDate = urlParamObj.paymentStartDate ? urlParamObj.paymentStartDate : obj.paymentStartDate;
      obj.paymentEndDate = urlParamObj.paymentEndDate ? urlParamObj.paymentEndDate : obj.paymentEndDate;
      obj.settleStartDate = urlParamObj.settleStartDate ? urlParamObj.settleStartDate : obj.settleStartDate;
      obj.settleEndDate = urlParamObj.settleEndDate ? urlParamObj.settleEndDate : obj.settleEndDate;
    }
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined) delete obj[key];
    }
    // 종료함
    return obj;
  })();
  // 번역도구
  const { t, i18n } = useTranslation();
  // 가격 유형 무료/유료/전체
  const [priceType, setPriceType] = useState(`${t('allProject')}`);

  const searchPeriodList = [
    { id: 1, label: t('paymentDate'), value: 'payment' },
    { id: 2, label: t('orderDate'), value: 'order' },
    { id: 3, label: t('settle.fix_date'), value: 'settle' },
  ];
  const productPriceTypeList = [
    { id: 1, label: t('allProject') },
    { id: 2, label: t('generalProject') },
    { id: 3, label: t('freeProject') },
  ];

  // 브랜드 가져오기
  const getBrandResultObj = useQuery(GET_BRANDS, {
    variables: {
      lang: 'ko',
    },
  });
  const getSettlePrice = useQuery(GET_SETTLE_PRICE, {
    variables: {
      brandId: urlParamObj.brandId,
      ...(priceType !== t('allProject') && { isFree: priceType === t('freeProject') }),
      startDate: urlParamObj.startDate,
      endDate: urlParamObj.endDate,
      paymentStartDate: urlParamObj.paymentStartDate,
      paymentEndDate: urlParamObj.paymentEndDate,
      settleStartDate: urlParamObj.settleStartDate,
      settleEndDate: urlParamObj.settleEndDate,
      goodsNm: urlParamObj.goodsNm,
      penNm: urlParamObj.penNm,
    },
  });
  const [getAllSettleItems, allSettleItems] = useLazyQuery(GET_SETTLE, {
    onCompleted: () => {
      downloadExcel('excelData', null);
    },
  });
  const [getAllSettleAdminItems, allSettleAdminItems] = useLazyQuery(GET_SETTLE_ALL, {
    onCompleted: () => {
      downloadExcel('excelAdminData', '정산내역');
    },
  });

  const { loading, data } = useGetSettleListQuery({
    variables: {
      ...(priceType !== t('allProject') && { isFree: priceType === t('freeProject') }),
      brandId: urlParamObj.brandId,
      startDate: urlParamObj.startDate as string,
      endDate: urlParamObj.endDate as string,
      paymentStartDate: urlParamObj.paymentStartDate as string,
      paymentEndDate: urlParamObj.paymentEndDate as string,
      settleStartDate: urlParamObj.settleStartDate as string,
      settleEndDate: urlParamObj.settleEndDate as string,
      page: urlParamObj.page,
      limit: urlParamObj.limit,
      goodsNm: urlParamObj.goodsNm as string,
      penNm: urlParamObj.penNm as string,
    },
  });

  // 엑셀 다운로드하기 메소드
  const downloadExcel = (id, name) => {
    var excelStr = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
    excelStr = excelStr + '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><style> td.str { mso-number-format:"0"; } </style>';
    excelStr = excelStr + '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
    excelStr = excelStr + '<x:Name>정산내역 리스트</x:Name>';
    excelStr = excelStr + '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
    excelStr = excelStr + '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>';
    excelStr = excelStr + "<table border='1px'>";
    excelStr = excelStr + document.getElementById(id).outerHTML;
    excelStr = excelStr + '</table></body></html>';
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    // 브랜드 명
    let brandName = urlParamObj.brandId !== '0' ? getBrandResultObj.data.getBrands.find((x) => x.id.toString() === urlParamObj.brandId)?.name : '관리자';
    let fileName = isReadAll ? `판매내역페이지_${brandName}.xls` : '판매내역페이지.xls';
    // 이름이 존재할 경우, 지정된 이름으로 파일 다운
    fileName = name ? `${name}.xls` : fileName;
    //Explorer 환경에서 다운로드
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      if (window.navigator.msSaveBlob) {
        var blob = new Blob([excelStr], {
          type: 'application/csv;charset=utf-8;',
        });
        navigator.msSaveBlob(blob, fileName);
      }
    } else {
      var blob2 = new Blob([excelStr], {
        type: 'application/csv;charset=utf-8;',
      });
      var filename = fileName;
      var elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob2);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  };

  // 색상변경
  useEffect(() => {
    // 배경 흰색으로 설정
    setBackgroundColor('white');

    return () => {
      setBackgroundColor('');
    };
  }, [setBackgroundColor]);

  // 검색 버튼 클릭 이벤트 처리기 메소드
  const onClickSearchButtonTag = (e) => {
    if (new Date(startDate) < new Date('2021-02-01T00:00:00')) {
      setIsShowNoticeModal(true);
    }
    // url 객체
    let searchObj = { ...urlParamObj };
    delete searchObj.settleStartDate;
    delete searchObj.settleEndDate;
    delete searchObj.paymentStartDate;
    delete searchObj.paymentEndDate;
    delete searchObj.startDate;
    delete searchObj.endDate;
    searchObj.page = 1;

    switch (dateSearchType) {
      case 'settle':
        searchObj.settleStartDate = startDate;
        searchObj.settleEndDate = endDate;
        break;
      case 'payment':
        searchObj.paymentStartDate = startDate;
        searchObj.paymentEndDate = endDate;
        break;
      case 'order':
        searchObj.startDate = startDate;
        searchObj.endDate = endDate;
        break;
    }
    // 검색 쿼리
    const query = QueryString.stringify(searchObj);
    // 검색페이지로 이동
    history.push({
      pathname: history.location.pathname,
      search: query,
    });
  };
  // 프로젝트 유형 checkbox 변경 이벤트 처리기 메소드
  const onChangeProjectType = (e) => {
    // 무료 유형 설정
    setPriceType(productPriceTypeList.find((x) => x.id === e.target.value).label);
  };
  // 항목 보기 개수 select 태그 변경 이벤트 처리기 메소드
  const onChangeViewCountSelectTag = (e) => {
    const value = viewCountList.find((x) => x.id === e.target.value).label;
    // 검색 쿼리
    const query = QueryString.stringify({
      ...urlParamObj,
      page: 1,
      limit: value,
    });
    sessionStorage.setItem('settleview', value);
    // 검색페이지로 이동
    history.push({
      pathname: history.location.pathname,
      search: query,
    });
  };

  // 전체 다운로드 클릭 이벤트 처리기 메소드
  const onClickAllDownload = () => {
    // 전체 다운로드
    getAllSettleAdminItems({
      variables: {
        startDate: urlParamObj.startDate,
        endDate: urlParamObj.endDate,
        paymentStartDate: urlParamObj.paymentStartDate,
        paymentEndDate: urlParamObj.paymentEndDate,
        settleStartDate: urlParamObj.settleStartDate,
        settleEndDate: urlParamObj.settleEndDate,
      },
    });
  };
  // 엑셀 다운로드 버튼 클릭 이벤트 처리기 메소드
  const onClickExcelDownload = () => {
    getAllSettleItems({
      variables: {
        brandId: urlParamObj.brandId,
        ...(priceType !== t('allProject') && { isFree: priceType === t('freeProject') }),
        startDate: urlParamObj.startDate,
        endDate: urlParamObj.endDate,
        paymentStartDate: urlParamObj.paymentStartDate,
        paymentEndDate: urlParamObj.paymentEndDate,
        settleStartDate: urlParamObj.settleStartDate,
        settleEndDate: urlParamObj.settleEndDate,
        goodsNm: urlParamObj.goodsNm,
        penNm: urlParamObj.penNm,
      },
    });
  };
  const onChangeBrandSelectTag = (e) => {
    if (!getBrandResultObj.data.getBrands.find((y) => y.name === e.target.value)) return;

    const obj = {
      ...urlParamObj,
      brandId: getBrandResultObj.data.getBrands.find((y) => y.name === e.target.value).id,
    };
    obj.page = 1;
    obj.limit = 20;
    obj.goodsNm = '';
    obj.penNm = '';
    // 검색 쿼리
    const query = QueryString.stringify(obj);
    // 검색페이지로 이동
    history.push({
      pathname: history.location.pathname,
      search: query,
    });
  };
  const onClickResetButtonTag = () => {
    sessionStorage.removeItem('settleview');
    delete urlParamObj.goodsNm;
    delete urlParamObj.penNm;

    const queryStr = QueryString.stringify(urlParamObj);
    // 검색페이지로 이동
    history.push(`?${queryStr}`);
  };
  const onChangeDateTypeSelectTag = (e) => {
    if (!searchPeriodList.find((x) => x.id === e.target.value)) return;
    setDateSearchType(searchPeriodList.find((x) => x.id === e.target.value)?.value);
  };

  const renderExcelTable = (tableId, items) => {
    return (
      <ExcelTable id={tableId} style={{ display: 'none' }}>
        <THead>
          <Tr>
            {isReadAll && <Th rowSpan="2">{t('brand')}</Th>}
            {isReadAll && <Th rowSpan="2">{t('orderNo')}</Th>}
            <Th rowSpan="2">{t('settleTable.penName')}</Th>
            <Th rowSpan="2">{t('settleTable.buyername')}</Th>
            <Th rowSpan="2">{t('settleTable.projectTitle')}</Th>
            <Th rowSpan="2">{t('settleTable.orderDate')}</Th>
            <Th rowSpan="2">{t('settleTable.paymentDate')}</Th>
            {isReadAll && <Th rowSpan="2">{t('settleTable.settlementDate')}</Th>}
            <Th rowSpan="2">{t('settleTable.normalPrice')}</Th>
            <Th colSpan="2" style={{ borderBottom: '1px solid white' }}>
              {t('settleTable.creatorDiscount')}
            </Th>
            <Th rowSpan="2">
              {t('settleTable.settlePrice')}
              <br />
              (D=A-B-C)
            </Th>
            <Th rowSpan="2">{t('settleTable.appliedWork')}</Th>
          </Tr>
          <Tr>
            <Th>{t('settleTable.benefitDiscount')}</Th>
            <Th>{t('settleTable.couponDiscount')}</Th>
          </Tr>
        </THead>
        <TBody>
          {items.map((x, i) => (
            <Tr>
              {isReadAll && (
                <Td>
                  <span>{getBrandResultObj.data.getBrands.find((y) => y.godoBrandCd === x.brandCd)?.name}</span>
                </Td>
              )}
              {isReadAll && <Td className="str">'{x.orderNo}</Td>}
              <Td>
                <span>{x.orderMemo.length > 0 ? x.orderMemo.split(/\\n/)[1] || x.orderMemo : '-'}</span>
              </Td>
              <Td>
                <span>{x.orderName}</span>
              </Td>
              <Td>
                <span>{x.goodsNm}</span>
              </Td>
              <Td>{dayjs(x.regDt).format('YY-MM-DD\u00a0\u00a0HH:mm')}</Td>
              <Td>{dayjs(x.paymentDt).format('YY-MM-DD\u00a0\u00a0HH:mm')}</Td>
              {isReadAll && <Td>{dayjs(x.settleDt).format('YY-MM-DD')}</Td>}
              <Td>
                {(() => {
                  const value = AconHelper.AddCommas(Number(x.goodsPrice));
                  return <span>{value}</span>;
                })()}
              </Td>
              <Td>
                {(() => {
                  const value = AconHelper.AddCommas(Number(x.goodsDcPrice));
                  return <span>{value}</span>;
                })()}
              </Td>
              <Td>
                {(() => {
                  const value = AconHelper.AddCommas(Number(x.couponGoodsDcPrice));
                  return <span>{value}</span>;
                })()}
              </Td>
              <Td>
                {(() => {
                  const value = AconHelper.AddCommas(Number(x.goodsPrice) - Number(x.goodsDcPrice) - Number(x.couponGoodsDcPrice));
                  return <span>{value}</span>;
                })()}
              </Td>
              <Td className="projectNameValue">
                <Field width="95">{x.licenseType === '개인' ? <span className="projectNameValue__personal">{t('personalLicense')}</span> : <span>{x.licenseType}</span>}</Field>
              </Td>
            </Tr>
          ))}
        </TBody>
      </ExcelTable>
    );
  };

  // table sort method
  const [sortingOrder, setSortingOrder] = useState<{
    normalPrice: 'asc' | 'desc' | null;
    discount: 'asc' | 'desc' | null;
    settlePrice: 'asc' | 'desc' | null;
  }>({ normalPrice: null, discount: null, settlePrice: null });
  const clickColumn = (type) => {
    const order = { normalPrice: null, discount: null, settlePrice: null };
    const getVal = () => {
      switch (sortingOrder[type]) {
        case 'asc':
          return 'desc';
        case 'desc':
          return null;
        case null:
          return 'asc';
      }
    };
    setSortingOrder({
      ...order,
      [type]: getVal(),
    });
  };

  const SortArrowIcon = ({ type }: { type: 'normalPrice' | 'discount' | 'settlePrice' }) =>
    sortingOrder[type] ? (
      <Box
        sx={{
          position: 'absolute',
          right: '4px',
          top: '50%',
          transform: sortingOrder[type] === 'asc' ? 'translateY(-50%)' : 'translateY(-50%) rotate(180deg)',
        }}
      >
        <ArrowIcon />
      </Box>
    ) : (
      <></>
    );

  const sortList = (() => {
    let arr = data && [...data.getSettleListV2];
    if (!arr || arr.length === 0) return [];
    const normal = sortingOrder['normalPrice'];
    const discount = sortingOrder['discount'];
    const settle = sortingOrder['settlePrice'];

    let result = arr;
    let sortType = '';

    if (!normal && !discount && !settle) return result;
    if (normal) sortType = 'normalPrice';
    if (discount) sortType = 'discount';
    if (settle) sortType = 'settlePrice';

    arr.sort(function (a, b) {
      let newA = 0;
      let newB = 0;

      if (sortType === 'normalPrice') {
        newA = Number(a.goodsPrice);
        newB = Number(b.goodsPrice);
      }
      if (sortType === 'discount') {
        newA = Number(a.goodsDcPrice) + Number(a.couponGoodsDcPrice);
        newB = Number(b.goodsDcPrice) + Number(b.couponGoodsDcPrice);
      }
      if (sortType === 'settlePrice') {
        newA = Number(a.goodsPrice) - Number(a.goodsDcPrice) - Number(a.couponGoodsDcPrice);
        newB = Number(b.goodsPrice) - Number(b.goodsDcPrice) - Number(b.couponGoodsDcPrice);
      }
      if ((sortingOrder[sortType] === 'asc' && newB > newA) || (sortingOrder[sortType] === 'desc' && newB < newA)) return -1;
      if (newA === newB) return 0;
      return 1;
    });

    return arr;
  })();

  return (
    <>
      <SettlePopup
        modalId={'settlePopup'}
        isShow={isShowSettleModal}
        onHide={() => {
          setIsShowSettleModal(false);
        }}
        t={t}
      />
      <ProductPopup
        modalId={'prodPopup'}
        isShow={isShowProductModal}
        onHide={() => {
          setIsShowProductModal(false);
        }}
        t={t}
      />
      <NoticePopup
        modalId={'noticePopup'}
        isShow={isShowNoticeModal}
        onHide={() => {
          setIsShowNoticeModal(false);
        }}
        isFooter={true}
      />
      <Page className="settle_page" isAdmin={userInfo.isAdmin}>
        <Typography fontSize="24px" lineHeight="1.5" fontWeight="700" color={color.text.primary}>
          {t('settleList')}
        </Typography>
        {isReadAll && getBrandResultObj.data && (
          <Box mt="40px">
            <Title>{t('brand')}</Title>
            <SelectBox
              defaultValue={parseInt(urlParamObj.brandId, 10)}
              options={getBrandResultObj.data.getBrands.map((x) => ({ id: x.id, label: x.name }))}
              placeholder="선택"
              onSelect={onChangeBrandSelectTag}
              {...(!isMobile && {
                width: '336px',
              })}
              isKeyboardAvailable
              inputLabelVisible={false}
            />
          </Box>
        )}

        <Box mt="40px" />
        <Title>{t('settle.search_period')}</Title>
        <Box mb="40px" {...(!isMobile && { display: 'flex', alignItems: 'center' })}>
          <SelectBox
            defaultValue={Number(searchPeriodList.find((x) => x.value === dateSearchType)?.id || 1)}
            options={searchPeriodList}
            onSelect={onChangeDateTypeSelectTag}
            inputLabelVisible={false}
            {...(isMobile
              ? {
                  marginTop: '16px',
                  marginBottom: '16px',
                }
              : {
                  marginRight: '16px',
                  width: '160px',
                  '& fieldset': {
                    marginTop: 'auto',
                    height: '56px',
                  },
                })}
          />
          <DateRangePicker
            startDate={new Date(startDate)}
            endDate={new Date(endDate)}
            setStartDate={(val) => {
              const startDateStr = dayjs(val).format('YYYY-MM-DD') + 'T00:00:00';
              setStartDate(startDateStr);
              sessionStorage.setItem('startdate', startDateStr);
            }}
            setEndDate={(val) => {
              const endDateStr = dayjs(val).format('YYYY-MM-DD') + 'T23:59:59';
              setEndDate(endDateStr);
              sessionStorage.setItem('enddate', endDateStr);
            }}
            isMobile={isMobile}
          />
          <Button
            onClick={onClickSearchButtonTag}
            {...(isMobile
              ? {
                  marginTop: '16px',
                }
              : {
                  marginLeft: '16px',
                  width: '120px',
                })}
            padding="15px"
            variant="contained"
            colorTheme="primary"
          >
            {t('search')}
          </Button>
        </Box>

        <Wrap>
          <FlexColWrap className="settle_searchResult">
            <Typography fontSize="14px" lineHeight="22px" color={color.text}>
              {t('settle.search_result')}&nbsp;
              <span style={{ fontWeight: '700' }}>
                {data && data.getSettleCount.searchCount}&nbsp;/&nbsp;{t('total')}&nbsp;{data && data.getSettleCount.totalCount}
              </span>
            </Typography>
          </FlexColWrap>

          <Container marginTop="12px" fontSize="14px" lineHeight="22px">
            <Typography sx={{ '& span': { fontWeight: '700' } }}>
              {t('settle.search.totalAmount')}&nbsp;
              <span>{data && data.getSettleCount.searchCount}</span>
              &nbsp;{t('settle.search.unit')}
            </Typography>
            <Typography mt="4px" sx={{ '& span': { fontWeight: '700' } }}>
              {t('settle.search.totalPrice')}&nbsp;
              {!isKorean(i18n.language) && '$'}
              <span>{getSettlePrice && getSettlePrice.data && displayMoneyByLang(getSettlePrice.data.getSettleAllPrice, i18n.language).replace('$', '').replace('원', '')}</span>
              {isKorean(i18n.language) && <>&nbsp;원</>}
            </Typography>
          </Container>

          <Container marginTop="12px" wordBreak="keep-all">
            <Typography mb="4px" fontSize="14px" lineHeight="22px" fontWeight="700" color={color.primary}>
              {t('settle.information.title')}
            </Typography>
            <>
              {(dateSearchType !== 'settle'
                ? [t('settle.information.content1'), t('settle.information.content2'), t('settle.information.content3')]
                : [t('settle.information.content2'), t('settle.information.content3')]
              ).map((text) => (
                <Box
                  key={text}
                  display="flex"
                  color={color.text}
                  sx={{
                    '& p': {
                      fontSize: '14px',
                      lineHeight: '22px',
                      fontWeight: '400',
                    },
                  }}
                >
                  <Typography>•&nbsp;</Typography>
                  <Typography>{text}</Typography>
                </Box>
              ))}
            </>
          </Container>
        </Wrap>

        <Box mt="40px" px="12px" {...(!isMobile && { display: 'flex', alignItems: 'center' })}>
          <Typography mr="auto" fontSize="14px" lineHeight="22px" fontWeight="400" color={color.text} sx={{ wordBreak: 'keep-all' }}>
            {t('settleExcelWarning')}
          </Typography>
          <Button
            onClick={onClickExcelDownload}
            startIcon={<DownloadIcon style={{ marginRight: '8px' }} />}
            {...(isMobile
              ? {
                  marginTop: '8px',
                }
              : {
                  width: 'fit-content',
                })}
            minHeight="36px"
            wordBreak="keep-all"
          >
            <Typography fontSize="14px" lineHeight="22px" fontWeight="700">
              {t('excelDownload')}
            </Typography>
          </Button>
          {allSettleItems && allSettleItems.data && allSettleItems.data.getSettleListV2 && renderExcelTable('excelData', allSettleItems.data.getSettleListV2)}
          {isReadAll && (
            <>
              {(() => {
                let arr = [];
                if (allSettleAdminItems && allSettleAdminItems.data && allSettleAdminItems.data.getSettleAllListV2) {
                  arr = [...allSettleAdminItems.data.getSettleAllListV2];
                  arr.sort((x, y) => y.brandCd - x.brandCd);
                  return renderExcelTable('excelAdminData', arr);
                }
              })()}
              <Button
                onClick={onClickAllDownload}
                startIcon={<DownloadIcon style={{ marginRight: '8px' }} />}
                {...(isMobile
                  ? {
                      marginTop: '8px',
                    }
                  : {
                      marginLeft: '16px',
                      width: 'fit-content',
                    })}
                minHeight="36px"
                wordBreak="keep-all"
              >
                <Typography fontSize="14px" lineHeight="22px" fontWeight="700">
                  {t('excelAllDownload')}
                </Typography>
              </Button>
            </>
          )}
        </Box>

        <Container isPadding={false} marginTop="12px">
          <Box py="24px" display="flex" flexDirection="column">
            <Box {...(isMobile ? { px: 3 } : { display: 'flex', alignItems: 'center' })}>
              <Search history={history} />
              <Button
                onClick={onClickResetButtonTag}
                colorTheme="primary"
                fontWeight="700"
                wordBreak="keep-all"
                {...(isMobile
                  ? {
                      mt: 2,
                      height: '40px',
                    }
                  : {
                      ml: 2,
                      width: '160px',
                      height: '56px',
                      flexShrink: '0',
                    })}
              >
                <>{t('reset_search')}</>
              </Button>

              <SelectBox
                defaultValue={1}
                options={productPriceTypeList}
                onSelect={onChangeProjectType}
                placeholder={t('settle.generalOrFree')}
                {...(isMobile
                  ? {
                      mt: 2,
                    }
                  : {
                      m: '0 16px',
                      width: '160px',
                      flexShrink: '0',
                    })}
              />
            </Box>
          </Box>
          <Box sx={{ overflowX: 'scroll', '&::-webkit-scrollbar': { display: 'none' } }}>
            <Table sx={{ width: '1060px' }}>
              <TableHead sx={{ '& th': { p: '16px !important', fontSize: '13px !important', lineHeight: '24px', boxShadow: 'none !important', borderRadius: '0 !important' } }}>
                <TableRow>
                  <TableCell variant="head" width="222px">
                    {t('settleTable.projectTitle')}
                  </TableCell>
                  <TableCell variant="head" width="92px">
                    {t('settleTable.penName')}
                  </TableCell>
                  <TableCell variant="head" width="92px">
                    <span>{t('settleTable.appliedWork')}</span>
                  </TableCell>
                  <TableCell variant="head" width="142px">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>{selectedFilterValue === 'payment' ? t('settleTable.paymentDate') : t('settleTable.orderDate')}</span>
                      <Button
                        onClick={() => {
                          setIsShowFilter(!isShowFilter);
                        }}
                        variant="text"
                        colorTheme="normal"
                        width="12px"
                        minWidth="12px"
                        boxSizing="content-box"
                        marginLeft="auto"
                      >
                        <ChevronIcon />
                      </Button>
                    </Box>
                    <Filter isShow={isShowFilter} setIsShow={setIsShowFilter} setFilterValue={setSelectedFilterValue} filterValue={selectedFilterValue} />
                  </TableCell>
                  <TableCell variant="head" width="122px">
                    <span>{t('settleTable.settlementDate')}</span>
                  </TableCell>
                  <TableCell
                    variant="head"
                    width="130px"
                    sx={{ position: 'relative', transition: '0.5s', '&:hover': { bgcolor: palette.light.grey[300], cursor: 'pointer' } }}
                    onClick={() => clickColumn('normalPrice')}
                  >
                    <span>{t('settleTable.normalPrice')} &#40;A&#41;</span>
                    <SortArrowIcon type="normalPrice" />
                  </TableCell>
                  <TableCell
                    variant="head"
                    width="130px"
                    sx={{ position: 'relative', transition: '0.5s', '&:hover': { bgcolor: palette.light.grey[300], cursor: 'pointer' } }}
                    onClick={() => clickColumn('discount')}
                  >
                    <span>{t('settleTable.creatorDiscount')} &#40;B&#41;</span>
                    <SortArrowIcon type="discount" />
                  </TableCell>
                  <TableCell
                    variant="head"
                    width="136px"
                    sx={{ position: 'relative', transition: '0.5s', '&:hover': { bgcolor: palette.light.grey[300], cursor: 'pointer' } }}
                    onClick={() => clickColumn('settlePrice')}
                  >
                    <span>{t('settleTable.settlePrice')} &#40;A-B&#41;</span>
                    <SortArrowIcon type="settlePrice" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ '& td': { fontSize: '14px !important', lineHeight: '22px', p: '16px !important', textAlign: 'left !important' } }}>
                {data &&
                  data.getSettleListV2 &&
                  sortList.map((x, i) => {
                    // orderMemo에 있는 필명 사용하기 위한 코드
                    const penName = x.orderMemo.length > 0 ? x.orderMemo.split(/\\n/)[1] || x.orderMemo : '-';
                    return (
                      <TableRow
                        key={`settle table ${i}`}
                        sx={{
                          position: 'relative',
                          transition: '0.5s',
                          '&:hover': {
                            bgcolor: palette.light.grey[100],
                            cursor: 'pointer',
                          },
                          borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                        }}
                        onClick={() => {
                          localStorage.setItem('settle_url_params', JSON.stringify(urlParamObj));
                          window.location.href = `/manager/settle/${x.orderNo}/${x.goodsNo}`;
                        }}
                      >
                        <TableCell>
                          <Field>
                            <span>{x.goodsNm}</span>
                          </Field>
                        </TableCell>
                        <TableCell>
                          <Field width="60">
                            <span>{penName}</span>
                          </Field>
                        </TableCell>

                        <TableCell className="projectNameValue">
                          <Field width="64">
                            {x.licenseType === '개인' || x.licenseType === 'personal' ? (
                              <span>{t('personalLicense')}</span>
                            ) : x.licenseType ? (
                              <span>{t('companyLicense')}</span>
                            ) : (
                              <span className="projectNameValue__personal">{t('settleTable.noAppliedWork')}</span>
                            )}
                          </Field>
                        </TableCell>
                        <TableCell>
                          <Field>{dayjs(selectedFilterValue === 'payment' ? x.paymentDt : x.regDt).format('YY-MM-DD\u00a0\u00a0HH:mm')}</Field>
                        </TableCell>
                        <TableCell>
                          <Field width="84">
                            <span
                              style={{
                                color: new Date(dayjs(x.settleDt).format('YYYY-MM-DD') + ' 00:00:00') > new Date() ? '#c4c4c4' : '',
                              }}
                            >
                              {dayjs(x.settleDt).format('YY-MM-DD')}
                            </span>
                          </Field>
                        </TableCell>
                        <TableCell>
                          <Field width="98">
                            {(() => {
                              const value = displayMoneyByLang(Number(x.goodsPrice), i18n.language);
                              return <span>{value}</span>;
                            })()}
                          </Field>
                        </TableCell>
                        <TableCell>
                          <Field width="98">
                            {(() => {
                              const value = displayMoneyByLang(Number(x.goodsDcPrice) + Number(x.couponGoodsDcPrice), i18n.language);
                              return <span>{value}</span>;
                            })()}
                          </Field>
                        </TableCell>
                        <TableCell>
                          <Field width="104">
                            {(() => {
                              const value = displayMoneyByLang(Number(x.goodsPrice) - Number(x.goodsDcPrice) - Number(x.couponGoodsDcPrice), i18n.language);
                              return <span>{value}</span>;
                            })()}
                          </Field>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
          <Box display="flex" alignItems="center" px="24px">
            <Typography ml="auto" mr="20px">
              {t('settle.perPage')}:
            </Typography>
            <SelectBox defaultValue={1} options={viewCountList} onSelect={onChangeViewCountSelectTag} isBorder={false} width="80px" padding="0" marginRight="16px" />
            {!loading && data && data.getSettleCount !== undefined && (
              <PageNavigation totalCnt={data.getSettleCount.searchCount} limit={urlParamObj.limit} pathName={'/manager/settle'} history={history} />
            )}
          </Box>
        </Container>
        <Box pt="100px" />
      </Page>
    </>
  );
};
