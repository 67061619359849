import React, { useState, createContext } from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Message.scss'

// 메세지
export const MessageContext = createContext()
export default function (props) {
    const { t } = useTranslation();
    const [status, setStatus] = useState(normalData(props))
    // function Message() {
    function normalData(data) {
        const show = data && data.show ? data.show : false
        const title = data && data.title ? data.title : ''
        const message = data && data.message ? data.message : ''
        return { show, title, message }
    }
    function close() {
        setStatus(prevStatus => ({
            ...prevStatus,
            show: false
        }))
    }
    function openMessage({
        title,
        message
    }) {
        var data = {
            title,
            message,
            show: true
        }
        if (JSON.stringify(data) !== JSON.stringify(status)) {
            setStatus(normalData(data))
        }
    }
    return (
        < MessageContext.Provider value={openMessage} >
            <Modal className="message" show={status.show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {status.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {status.message}
                </Modal.Body>
                <Modal.Footer>
                    <button className="closeBtn" onClick={close}>
                        {t('close')}
                    </button>
                </Modal.Footer>
            </Modal>
            {props.children}
        </MessageContext.Provider >
    )
}
