import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'rsuite';
import {
    StyledWhiteButton,
    StyledVividButton,
    StyledBlackButton,
    StyledNormalButton,
    StyledFilterButton
} from './style';
import { Anchor } from '../style';

const handleLinkWrapping = (Component, props) => {
    const { children, ...rest } = props;

    if (props.href) {
        return (
            <Component {...rest}>
                <Anchor href={props.href} target="_blank">
                    {children}
                </Anchor>
            </Component>
        );
    }
    if (!props.to) {
        return (
            <Component {...rest}>
                {children}
            </Component>
        );
    }
    return (
        <NavLink to={props.to}>
            <Component {...rest}>
                {children}
            </Component>
        </NavLink>
    );
};

export const WhiteButton = props => {
    return handleLinkWrapping(StyledWhiteButton, props);
};
export const VividButton = props => {
    return handleLinkWrapping(StyledVividButton, props);
};
export const BlackButton = props => {
    return handleLinkWrapping(StyledBlackButton, props);
};
// 별도 스타일이 존재하지 않은 버튼 태그입니다. 
export const NormalButton = props => {
    return handleLinkWrapping(StyledNormalButton, props);
};
// 필터이미지가 적용된 버튼입니다.
export const FilterButton = ((props) => {
    return (<StyledFilterButton {...props}>
        <Icon icon="filter" />
    </StyledFilterButton>);
});
export const ArrowDownButton = ((props) => {
    return (<StyledFilterButton {...props}>
        <Icon icon="arrow-down" />
    </StyledFilterButton>);
});
export const QustionCircleButton = ((props) => {
    return (<StyledFilterButton {...props}>
        <Icon icon="question-circle2" />
    </StyledFilterButton>);
});
export const CloseButton = ((props) => {
    return (<StyledFilterButton {...props}>
        <Icon icon="close" />
    </StyledFilterButton>);
});