import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  ${(props) =>
    props.type === "space-between" ? "justify-content: space-between;" : ""}
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ""}
    ${(props) => (props.align === "center" ? "align-items: center;" : "")}
`;

export const Value = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 17px;
  position: relative;
`;

const Button = styled.button`
  font-size: 14px;
  margin: 1px 2.5px;
  border-radius: 3px;
  min-width: 65px;
  height: 38px;
  color: white;
  &[disabled] {
    opacity: 0.7;
  }
`;

const UnderButton = styled(Button)`
  background-color: inherit;
  font-size: 12px;
  color: #333;
  text-decoration: underline;
  font-weight: 400;
  line-height: 100%;
  height: auto;
`;
const BlackButton = styled(Button)`
  background: #333;
`;
const GrayButton = styled(Button)`
  background: #666;
`;

const VividButton = styled(Button)`
  background: #ff007b;
`;
const WhiteButton = styled(Button)`
  background: white;
  ${(props) => (props.height ? `height: ${props.height}px;` : ``)}
  border: 1px solid #7c7c7c;
  font-weight: 500;
  color: #333;
  font-size: 12px;
`;

export default Button;
export { BlackButton, WhiteButton, GrayButton, VividButton, UnderButton };
export const Container = styled.div`
  width: 585px;
  margin: 0 auto;
`;
/* 필드 */
export const Field = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 60px;
  font-weight: bold;

  ${(props) => (props.type === "col-4" ? "width: 33px;" : "")}
  ${(props) => (props.isDisp ? `pointer-events: none;` : ``)}
    & > ${Input} {
    font-size: 16px;
  }
`;

/* 제목 컴포넌트  */
export const Title = styled.div`
  font-size: 15px;
  font-weight: 700;
  position: relative;
  margin-bottom: 12px;
  & > span {
    margin-top: 2px;
  }
`;
/* 제목 컴포넌트  */
export const FieldTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  position: relative;
  margin-bottom: 12px;
  & > span {
    margin-top: 2px;
  }
`;
/* input 컴포넌트 */
export const Input = styled.input.attrs((props) => ({
  type: "text",
}))`
  width: 100%;
  min-height: 41px;
  resize: none;
  padding: 9.5px 16px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
`;

export const Box = styled.div`
  width: 100%;
  ${(props) => (props.height ? `min-height: ${props.height}px;` : ``)}
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 60px 77px 36px 77px;
`;
export const Description = styled.div`
  width: 100%;
  height: auto;
  font-size: 12px;
  color: #767676;
  font-weight: 400;
`;
export const InputMessage = styled.div`
  width: 100%;
  height: auto;
  font-size: 12px;
  line-height: 100%;
  color: #f300ba;
  font-weight: 400;
  margin-top: 12px;
`;

export const CommonInputTextBox = styled.input.attrs({
  type: "text",
})`
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 3px;
  height: 38px;
  width: 100%;
  ${(props) =>
    `margin: ${props.margin ? `${props.margin}` : "10px 0px 0px 0px"};`}
  ${(props) =>
    `padding: ${props.padding ? `${props.padding}` : "0px 0px 0px 10px"};`}
`;

export const CommonInputRadioBox = styled.input.attrs({
  type: "radio",
})`
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  margin-right: 5px;
  vertical-align: middle;
`;

export const CommonInputCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  margin-left: 20px;
  margin-right: 5px;
`;

export const LabelText = styled.label`
  font-weight: 400;
`;

export const Table = styled.table`
  width: 100%;
`;

export const Thead = styled.thead``;

export const Th = styled.th`
  width: 20%;
  height: 40px;
  line-height: 40px;
  text-align: left;
  padding-left: 10px;
  border: 1px solid #cdcdcd;
  font-weight: normal;
`;

export const Tbody = styled.tbody``;

export const Td = styled.td`
  width: 20%;
  height: 40px;
  line-height: 40px;
  text-align: left;
  padding-left: 10px;
  border: 1px solid #cdcdcd;
  font-weight: normal;
`;

export const BenefitInputBox = styled(CommonInputTextBox).attrs({
  placeholder: "입력해주세요",
})`
  border: none;
  outline: none;
  margin: 0px;
  padding: 0px;
`;
