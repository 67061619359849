import { LANG_CODE, NEW_STATUS_DRAFT, STATUS_COMPLETE, STATUS_COMPLETE_ON_OPEN, STATUS_DENIED, STATUS_DRAFT_ON_OPEN, STATUS_IN_REVIEW, STATUS_IN_REVIEW_ON_OPEN, STATUS_ON_SALE, STATUS_ON_SALE_ON_OPEN, STATUS_REJECTED, STATUS_REJECTED_ON_OPEN, STATUS_REQUESTED, STATUS_REQUESTED_ON_OPEN } from "boards/DetailBoardWrite/constants";
import { DefaultValue, selector } from "recoil";
import { applicationsState, assetIdState, brandState, categoriesState, completedState, contentBodyState, contentHeadState, copyrightState, createdState, displayState, extensionState, fileState, gameState, idState, isAdultOnlyState, keywordsState, languageState, mainImageState, memoState, messageState, originalLanguageState, priceChangedState, priceState, requestedState, statusState, subImageState, titleState, updateRequestState, versionState, webtoonState } from "..";
import { DocumentDataType } from "../type"

export const documentDataSelector = selector<DocumentDataType>({
  key: 'documentDataSelector',
  get: ({ get }) => {
    const id = get(idState);
    const assetId = get(assetIdState);
    const brand = get(brandState);
    const language = get(languageState);
    const originalLanguage = get(originalLanguageState);
    const title = get(titleState);
    const contentHead = get(contentHeadState);
    const contentBody = get(contentBodyState);
    const file = get(fileState);
    const extensions = get(extensionState);
    const applications = get(applicationsState);
    const mainImage = get(mainImageState);
    const subImage = get(subImageState);
    const categories = get(categoriesState);
    const price = get(priceState);
    const keywords = get(keywordsState);
    const copyright = get(copyrightState);
    const status = get(statusState);
    const memo = get(memoState);
    const created = get(createdState);
    const requested = get(requestedState);
    const completed = get(completedState);
    const version = get(versionState);
    const { isEdit, isDisp } = get(displayState);
    const updateRequest = get(updateRequestState);
    const priceChanged = get(priceChangedState);
    const isAdultOnly = get(isAdultOnlyState);
    const webtoon = get(webtoonState);
    const game = get(gameState);
    const message = get(messageState);

    return {
      id,
      assetId,
      brand,
      language,
      originalLanguage,
      title,
      contentHead,
      contentBody,
      file,
      extensions,
      applications,
      mainImage,
      subImage,
      categories,
      price,
      keywords,
      copyright: {
        isOriginal: copyright.isOriginal,
        commercialSources: copyright.commercialSources,
        warehouseSources: copyright.warehouseSources,
        hasRealLogo: copyright.hasRealLogo,
      },
      status,
      memo,
      created,
      requested,
      completed,
      version,
      isEdit,
      isDisp,
      updateRequest,
      priceChanged,
      isAdultOnly,
      webtoon,
      game,
      message,
    };
  },
  set: ({ get, set }, val) => {
    if (!(val instanceof DefaultValue)) {
      const {
        id,
        assetId,
        brand,
        language,
        originalLanguage,
        title,
        contentHead,
        contentBody,
        file,
        extensions,
        applications,
        mainImage,
        subImage,
        categories,
        price,
        keywords,
        copyright,
        status,
        memo,
        created,
        requested,
        completed,
        version,
        priceChanged,
        isAdultOnly,
        webtoon,
        game,
        message,
      } = val;

      if (typeof id === 'string') set(idState, id);
      if (typeof assetId === 'number') set(assetIdState, assetId);
      if (typeof brand === 'number') set(brandState, brand);
      if (
        language === LANG_CODE.KO ||
        language === LANG_CODE.EN ||
        language === LANG_CODE.ZH ||
        language === LANG_CODE.JA
      ) {
        set(languageState, language);
      }
      if (
        originalLanguage === LANG_CODE.KO ||
        originalLanguage === LANG_CODE.EN ||
        originalLanguage === LANG_CODE.ZH ||
        originalLanguage === LANG_CODE.JA
      ) {
        set(originalLanguageState, originalLanguage);
      }
      if (typeof title === 'string') {
        set(titleState, title);
      }
      if (typeof contentHead === 'string') {
        set(contentHeadState, contentHead);
      }
      if (typeof contentBody === 'string') {
        set(contentBodyState, contentBody);
      }
      if (file) {
        set(fileState, file);
      }

      if (Array.isArray(extensions)) {
        set(extensionState, extensions);
      }
      if (Array.isArray(applications)) {
        set(applicationsState, applications);
      }

      const oldMainImage = get(mainImageState);
      set(mainImageState, {
        ...oldMainImage,
        ...mainImage,
      });
      const oldSubImage = get(subImageState);
      set(subImageState, {
        ...oldSubImage,
        ...subImage,
      });

      set(categoriesState, {
        genre: {
          categoryBranch: categories?.genre?.categoryBranch,
        },
        theme: {
          categoryBranch: categories?.theme?.categoryBranch,
        },
        game: {
          categoryBranch: categories?.game?.categoryBranch,
        },
      });
      if (typeof price === 'number') {
        set(priceState, price);
      }
      if (Array.isArray(keywords)) {
        set(keywordsState, keywords);
      }
      if (
        typeof copyright?.isOriginal === 'boolean'
      ) {
        set(copyrightState, copyright);
      }
      if (
        status === NEW_STATUS_DRAFT ||
        status === STATUS_REQUESTED ||
        status === STATUS_IN_REVIEW ||
        status === STATUS_DENIED ||
        status === STATUS_REJECTED ||
        status === STATUS_REJECTED_ON_OPEN ||
        status === STATUS_COMPLETE ||
        status === STATUS_DRAFT_ON_OPEN ||
        status === STATUS_REQUESTED_ON_OPEN ||
        status === STATUS_IN_REVIEW_ON_OPEN ||
        status === STATUS_COMPLETE_ON_OPEN ||
        status === STATUS_ON_SALE ||
        status === STATUS_ON_SALE_ON_OPEN
      ) {
        set(statusState, status);
      }
      if (memo) {
        set(memoState, memo);
      }
      if (created) {
        set(createdState, created);
      }
      if (requested) {
        set(requestedState, requested);
      }
      if (completed) {
        set(completedState, completed);
      }
      if (typeof version === 'number') {
        set(versionState, version);
      }

      const isEdit = [
        STATUS_REJECTED,
        STATUS_REJECTED_ON_OPEN,
        STATUS_DRAFT_ON_OPEN,
        STATUS_REQUESTED_ON_OPEN,
        STATUS_IN_REVIEW_ON_OPEN,
        STATUS_COMPLETE_ON_OPEN,
        STATUS_ON_SALE_ON_OPEN,
      ].includes(status);

      const isDisp = ![
        NEW_STATUS_DRAFT,
        STATUS_DRAFT_ON_OPEN,
      ].includes(status);

      set(displayState, { isEdit, isDisp });
      set(priceChangedState, priceChanged);

      if (typeof isAdultOnly === 'boolean') {
        set(isAdultOnlyState, isAdultOnly);
      }

      if (typeof webtoon === 'boolean') set(webtoonState, webtoon);
      if (typeof game === 'boolean') set(gameState, game);
      if (typeof message === 'string') set(messageState, message);
    }
  },
});