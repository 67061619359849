import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Field, Title } from 'acon-mui/components/Board';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { displayState, gameState, statusState, webtoonState } from 'boards/DetailBoardWrite/recoil';
import React, { useMemo, } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

export default function DocumentType() {
  const { t } = useTranslation();
  const display = useRecoilValue(displayState);
  const status = useRecoilValue(statusState);
  const [webtoon, setWebtoon] = useRecoilState(webtoonState);
  const [game, setGame] = useRecoilState(gameState);
  const disabled = useMemo(() => display.isDisp || status === STATUS_DRAFT_ON_OPEN, [display.isDisp, status]);

  return (
    <Field className="hubWrite_field_document_type" mt={3}>
      <Title
        checkboxVisible={false}
        marginRight="auto"
      >
        {t("document.productType")}
      </Title>
      <Box 
        display="flex" 
        alignItems="center" 
        color="#000000DE"
        letterSpacing="0.15px"
      >
        <FormGroup sx={{ ml: -1, '& label': { margin: '0 !important' } }}>
          <FormControlLabel
            control={<Checkbox checked={webtoon} disabled={disabled} onChange={(e) => setWebtoon(e.target.checked)} />}
            label={t('document.toon')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                lineHeight: '24px',
              },
            }}
          />
        </FormGroup>
        <FormGroup sx={{ ml: 1, '& label': { margin: '0 !important' } }}>
          <FormControlLabel
            control={<Checkbox checked={game} disabled={disabled} onChange={(e) => setGame(e.target.checked)} />}
            label={t('document.game')}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '16px',
                lineHeight: '24px',
              },
            }}
          />
        </FormGroup>
      </Box>
    </Field>
  );
};