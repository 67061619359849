import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
  informationCards?: React.ReactNode; 
  className?: string;
  mt?: number | string;
};

export default function Field({
  children,
  informationCards=<></>,
  className,
  mt=6,
}: Props) {
  return (
    <Box {...className && { className }} mt={mt} display="flex">
      <Box flex="1">
        {children}
      </Box>
      <Box ml={3} width="276px" position="relative" display="flex" flexDirection="column">
        {informationCards}
      </Box>
    </Box>
  );
};
