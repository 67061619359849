import React from 'react';
import ImageUpload from 'boards/DetailBoardWrite/component/imageUpload';
import { useRecoilState } from 'recoil';
import { subImageSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import { extensions, fileSize, limit } from 'boards/DetailBoardWrite/component/imageUpload/constants';

export default (({
    imgType,
    setCallback,
    cropperTag,
    image,
    setImage,
    allImageEventHandler,
    disabled,
    isShow,
    isComplete,
    setComplete,
    setCompleteCallback,
    setFileCallback,
    message,
    showMessage,
    setCroppedImageBlob,
    setCroppedImageDataUrl,
    onDeleteImage,
}) => {
    const [subImage, setSubImage] = useRecoilState(subImageSelector);

    return (
        <ImageUpload
            showMessage={showMessage}
            setCallback={setCallback}
            cropperTag={cropperTag}
            image={image}
            setImage={setImage}
            allImageEventHandler={allImageEventHandler}
            setCompleteCallback={setCompleteCallback}
            setCroppedImageBlob={setCroppedImageBlob}
            setCroppedImageDataUrl={setCroppedImageDataUrl}
            isShow={isShow}
            disabled={disabled}
            isComplete={isComplete}
            setComplete={setComplete}
            minWidth={fileSize[0]}
            minHeight={fileSize[1]}
            extensions={extensions}
            sizeLimit={10}
            targetWidth={fileSize[0] / 2}
            targetHeight={fileSize[1] / 2}
            boxWidth={fileSize[0] * 0.4}
            boxHeight={fileSize[1] * 0.4}
            limit={limit}
            cropRatio={fileSize[0] / fileSize[1]}
            left={subImage.cropInfo?.imageOriginX}
            top={subImage?.cropInfo?.imageOriginY}
            width={subImage?.cropInfo?.imageWidth}
            height={subImage?.cropInfo?.imageHeight}
            url={subImage?.path}
            name={subImage?.name}
            setCropInfo={value => {
                setSubImage({ 
                    cropInfo: value 
                });
            }}
            setImageUrl={(key, name) => {
                setSubImage({ 
                    path: key,
                    name: name
                });
            }}
            setFileCallback={setFileCallback}
            onDeleteImage={onDeleteImage}
        />
    );
});