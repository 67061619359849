import React, { useEffect, useState } from 'react';
import * as Styled from '../../style';
import { STATUS_CODE } from '../../constants';
import { useQuery } from '@apollo/client';
import { GLOBAL_PAYMENT_FEE_LIST } from '../../constants';
import { GET_BRANDS_NOT_HAVING_USER } from 'api/quries';
import { SmallWhiteButton } from 'common/style';
import RcSelect from 'react-select';
import { ValidationText } from '../../style/input';

const Component = (props) => {
    const { data, downloadTerm, inputRefs } = props;
    // 기존 브랜드 선택하기 여부
    const [isExistBrand, setIsExistBrand] = useState(false);

    // 브랜드 정보
    const { data: brandData, loading } = useQuery(GET_BRANDS_NOT_HAVING_USER);
    const isLoading = !(!loading && brandData && brandData.getBrandsNotHavingUser);

    // 회원 정보
    const userData = data?.user;
    const userPlan = data?.userPlan;

    // 작가 승인상태
    const acceptStatus = userData.accept.status;
    // 작가 수정상태
    const modifyStatus = userData.accept.modifyStatus;
    // 수정 요청 상태일 경우
    const isModifyRequest = data?.prevModifyStatus === STATUS_CODE.REQUEST;
    // 상태 값
    const STATUS = {
        accept: {
            reject: acceptStatus === STATUS_CODE.REJECT,
            request: acceptStatus === STATUS_CODE.REQUEST,
            approve: acceptStatus === STATUS_CODE.APPROVE,
        },
        modify: {
            reject: modifyStatus === STATUS_CODE.REJECT,
            request: modifyStatus === STATUS_CODE.REQUEST,
            approve: modifyStatus === STATUS_CODE.APPROVE,
        },
    }

    // 값 변경
    const onChangeData = (e) => {
        const element = e.target;
        const key = element.dataset.key;
        let value = element.value;

        if (element.dataset.allowNum) {
            value = element.value.replace(/[^0-9\-]/g, '');
            element.value = value;
        }

        props.onChangeData(key, value);
    };

    // 작가 승인상태 변경
    const onChangeStatus = (value) => {
        const idx = userData.accept.statusStringList.indexOf(value) - 1;
        props.onChangeData('data.user.accept.status', idx);
    };

    // 작가 수정상태 변경
    const onChangeModifyStatus = (value) => {
        const idx = userData.accept.statusStringList.indexOf(value) - 1;
        const changeValue = {
            ...data.user.accept,
            modifyStatus: idx,
        };

        // 수정 상태 요청으로 변경시 승인, 반려 사유 초기화
        if (idx === STATUS_CODE.REQUEST) {
            changeValue.modifyRejectComment = '';
            changeValue.modifyApproveComment = '';
        }

        props.onChangeData('data.user.accept', changeValue);
    };

    // 수수료율 변경 이벤트
    const onChangeComissionRate = (value) => {
        props.onChangeData('data.userPlan.commissionRate', value);
    };

    // 브랜드 코드 변경
    const onChangeExistBrand = (data) => {
        props.onChangeData('data.brandId', data.value);
    }

    useEffect(() => {
        props.onChangeData('data.isExistBrand', isExistBrand);
    }, [isExistBrand]);

    const addToRefs = el => {
        if (el && !inputRefs.current.includes(el)) {
            inputRefs.current.push(el);
        }
    };

    const brands = userData.brand.item.slice().sort((a, b) => {
        return a.language.id - b.language.id;
    });

    return (
        <section>
            {/* 제목 */}
            <Styled.TitleBar>
                <Styled.Title>일반 정보</Styled.Title>
            </Styled.TitleBar>

            {/* 영역 */}
            <Styled.Contents>
                {/* 작가 승인 상태 */}
                <Styled.Field label={'작가 승인상태'}>
                    <Styled.Select
                        data={userData.accept.statusStringList}
                        value={userData.accept.statusStringList[acceptStatus + 1]}
                        onChange={onChangeStatus}
                        disabled={data?.prevStatus === STATUS_CODE.APPROVE}
                    />
                </Styled.Field>

                { /* 승인 상태가 반려일 경우 반려사유 입력 폼 표시 */
                    STATUS.accept.reject &&
                    <>
                        <Styled.Field label="사유입력">
                            <Styled.Textarea
                                data-key="data.user.accept.comment"
                                defaultValue={userData.accept.comment}
                                onChange={onChangeData}
                                ref={addToRefs}
                                isEmphasis={!userData.accept.comment}
                            />
                            {!userData.accept.comment &&
                                <ValidationText>This field is required</ValidationText>
                            }
                        </Styled.Field>
                        <Styled.Field label="반려사유">
                            <Styled.Textarea
                                disabled={true}
                                defaultValue={data.comment}
                            />
                        </Styled.Field>
                    </>
                }

                { /* 승인 상태가 반려가 아닐경우 작가 수정상태 표시 */
                    !(STATUS.accept.reject) &&
                    <>
                        {/* 작가 수정 상태 */}
                        <Styled.Field label={'작가 수정상태'}>
                            <Styled.Select
                                data={userData.accept.statusStringList}
                                value={userData.accept.statusStringList[modifyStatus + 1]}
                                onChange={onChangeModifyStatus}
                                disabled={!isModifyRequest}
                            />
                        </Styled.Field>

                        {/* 반려사유 폼 */}
                        {STATUS.modify.reject && isModifyRequest &&
                            <>
                                <Styled.Field label="사유입력">
                                    <Styled.Textarea
                                        data-key={'data.user.accept.modifyRejectComment'}
                                        onChange={onChangeData}
                                        isEmphasis={!userData.accept.modifyRejectComment}
                                        ref={addToRefs}
                                    >
                                        {userData.accept.modifyRejectComment}
                                    </Styled.Textarea>
                                    {!userData.accept.modifyRejectComment &&
                                        <ValidationText>This field is required</ValidationText>
                                    }
                                </Styled.Field>
                                <Styled.Field label={"반려사유"}>
                                    <Styled.Textarea
                                        disabled={true}
                                    >
                                        {userData.accept.modifyRejectComment}
                                    </Styled.Textarea>
                                </Styled.Field>
                            </>
                        }

                        {/* 승인사유 폼 */}
                        {STATUS.modify.approve && isModifyRequest &&
                            <>
                                <Styled.Field label="사유입력">
                                    <Styled.Textarea
                                        data-key={'data.user.accept.modifyApproveComment'}
                                        onChange={onChangeData}
                                        isEmphasis={!userData.accept.modifyApproveComment}
                                        ref={addToRefs}
                                    >
                                        {userData.accept.modifyApproveComment}
                                    </Styled.Textarea>
                                    {!userData.accept.modifyApproveComment &&
                                        <ValidationText>This field is required</ValidationText>
                                    }
                                </Styled.Field>
                                <Styled.Field label={"승인사유"}>
                                    <Styled.Textarea
                                        disabled={true}
                                    >
                                        {userData.accept.modifyApproveComment}
                                    </Styled.Textarea>
                                </Styled.Field>
                            </>
                        }
                    </>
                }

                <Styled.Field label="수수료율">
                    <Styled.SelectBox
                        data={GLOBAL_PAYMENT_FEE_LIST}
                        value={(userPlan?.commissionRate ?? null)}
                        onChange={(value) => { onChangeComissionRate(value) }}
                        disabled={false}
                        isEmpty={((userPlan?.commissionRate ?? -1) < 0) && STATUS.accept.approve}
                    >
                    </Styled.SelectBox>
                </Styled.Field>

                {/* 아이디(이메일) */}
                <Styled.InputField
                    label="아이디(이메일)"
                    defaultValue={userData.account}
                    disabled={true}
                />

                {/* 필명 */}
                <Styled.InputField inputRef={addToRefs} label="필명" data-key="data.user.brand.koName" defaultValue={brands.find(x => x.language.code === 'ko')?.name} onChange={onChangeData} style={{ marginBottom: 10 }} />
                <Styled.Input inputRef={addToRefs} data-key="data.user.brand.enName" defaultValue={brands.find(x => x.language.code === 'en')?.name} onChange={onChangeData} placeholder={'영문 필명을 입력해 주세요.'} style={{ marginBottom: 10 }} />
                <Styled.Input inputRef={addToRefs} data-key="data.user.brand.cnName" defaultValue={brands.find(x => x.language.code === 'cn')?.name} onChange={onChangeData} placeholder={'중문 필명을 입력해 주세요.'} style={{ marginBottom: 10 }} />
                <Styled.Input inputRef={addToRefs} data-key="data.user.brand.jpName" defaultValue={brands.find(x => x.language.code === 'jp')?.name} onChange={onChangeData} placeholder={'일문 필명을 입력해 주세요.'} />

                {/* SNS 계정 */}
                <Styled.InputField
                    label="SNS 계정"
                    defaultValue={userData.snsUrl}
                    disabled={true}
                />

                { /* 입점 검토 자료 */}
                <Styled.Field label="입점 검토 자료">
                    {userData.reference.map((document) =>
                        <Styled.Input readOnly={true} key={document.id} defaultValue={document.url} />
                    )}
                </Styled.Field>

                {/* 계약서 */}
                <Styled.Field label="계약서">
                    <span>계약서 파일의 비밀번호는 작가님의 브랜드코드 입니다.</span>
                    <div>
                        <SmallWhiteButton onClick={downloadTerm}>다운로드</SmallWhiteButton>
                    </div>
                </Styled.Field>

                <Styled.Field label="브랜드코드">
                    <label>
                        &nbsp;&nbsp;
                        <input style={{ marginRight: '3px' }} type="checkbox" value={isExistBrand} onClick={() => setIsExistBrand(!isExistBrand)} />
                        기존 브랜드 선택하기
                    </label>
                    {isExistBrand ? (
                        !isLoading && (() => {
                            const brandItems = brandData.getBrandsNotHavingUser.map(x => {
                                return {
                                    value: x.id,
                                    label: x.name
                                }
                            });

                            return (
                                <div>
                                    <RcSelect
                                        {...{
                                            placeholder: '작가',
                                            isMulti: false,
                                            options: brandItems,
                                            onChange: onChangeExistBrand,
                                        }}
                                        style={{ border: '0 !important' }}
                                        ref={addToRefs}
                                    />
                                    {userData.accept.status === STATUS_CODE.APPROVE && !data?.brandId &&
                                        <ValidationText>This field is required</ValidationText>
                                    }
                                </div>
                            );
                        })()
                    ) : (
                        <Styled.Input
                            data-key="data.user.brand.godoBrandCd"
                            defaultValue={userData.brand.godoBrandCd || ''}
                            onChange={onChangeData}
                            inputRef={userData.accept.status === STATUS_CODE.APPROVE ? addToRefs: null}
                            isEmpty={userData.accept.status === STATUS_CODE.APPROVE && !userData.brand.godoBrandCd}
                        />
                    )}
                </Styled.Field>
            </Styled.Contents>
        </section>
    );
};

export default Component;