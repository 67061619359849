// 상태 코드
export const STATUS_CODE = {
    REJECT: -1, // 반려
    REQUEST: 0, // 요청
    APPROVE: 1, // 승인
}

// 해외몰 승인, 수정 상태 리스트
export const GLOBAL_STATUS_LIST = ['Denied', 'Applicant', 'Registered'];

// 해외몰 수수료 항목 리스트
export const GLOBAL_PAYMENT_FEE_LIST = [
    {
        value: null,
        text: '(Select)',
    },
    {
        value: 0,
        text: '0% (Early Entry)',
    },
    {
        value: 10,
        text: '10%',
    },
];

export const UPDATE_TYPE = {
    ALL: 'ALL',
    PAYMENT: 'payment',
    CREATOR: 'creator',
};