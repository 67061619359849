import styled from "styled-components";
import img from "../../img/image.png";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-top: 1px solid #d5d4d9;
  border-bottom: 1px solid #d5d4d9;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
`;
export const ColGroup = styled.colgroup``;
export const Col = styled.col``;
export const THead = styled.thead``;
export const TBody = styled.tbody``;
export const Tr = styled.tr``;
export const Th = styled.th`
  font-size: 12px;
  font-weight: 400;
  background-color: #ff007b;
  color: #fff;
  padding: 9px;
  text-align: center;
`;
export const Td = styled.td`
  font-size: 12px;
  border: 1px solid #d5d4d9;
  ${(props) =>
    props.align ? `text-align: ${props.align};` : `text-align: left;`}
  color: #333;
  padding: 14px 7px;

  text-shadow: 1px 1px 1px #fff;

  a,
  a:hover {
    color: black;
  }
  & > div {
  }
  &:first-of-type {
    border-left: 0;
  }
  &:last-of-type {
    border-right: 0;
  }
`;
export const Field = styled.div`
  ${(props) => (props.width ? `width: ${props.width}px;` : `width: 100%;`)}
  ${(props) => (props.height ? `height: ${props.height}px;` : ``)}
    ${(props) =>
    props.src && props.src !== "empty"
      ? `background-image: url(${props.src}); background-size: 52px 27px;`
      : props.src === "empty"
      ? `background-image: url(${img}); opacity: 0.2; background-size: 52px 27px;`
      : ""}
    ${(props) =>
    props.align === "center" ? "text-align:center; margin: 0 auto;" : ""}
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
