import axios from 'axios';

type CreateContractInput = {
  signatureAzureStoragePath: string;
  locale: string;
  userName: string;
  userContact: string;
  userPin: string;
};

/** creates a new contract and returns full Azure Storage path.
 * an Azure Storage path consists of ${container}/${path},
 * where it comes as 'settle/signedTerm/filename'
 * (do not ask me why it is named as 'signedTerm') */
export const createContract = async (data: CreateContractInput) => {
  const URL = `${process.env.NETWORK_PROTOCOL_GRAPHQL}://${process.env.NETWORK_HOST_GRAPHQL}/hub/create-contract`;
  const result = await axios.post(URL, data);
  return result.status === 201 ? result.data : null;
};

type UpdateContractInput = {
  userId: string;
  signatureAzureStoragePath: string;
  locale: string;
  userName: string;
  userContact: string;
  userPin: string;
  planType: string;
  commissionRate: number;
  password: string;
  isRejected?: boolean;
};

export const updateContract = async (data: UpdateContractInput) => {
  const URL = `${process.env.NETWORK_PROTOCOL_GRAPHQL}://${process.env.NETWORK_HOST_GRAPHQL}/hub/update-contract`;
  const result = await axios.post(URL, data);
  return result.status === 201 ? result.data : null;
};

type ConfirmContractInput = {
  signatureAzureSotragePath: string;
  locale: string;
  userName: string;
  userContact: string;
  userPin: string;
  contractDate: string;
};

export const confirmContract = async (data: ConfirmContractInput) => {
  console.log('예?');
  const URL = `${process.env.NETWORK_PROTOCOL_GRAPHQL}://${process.env.NETWORK_HOST_GRAPHQL}/hub/confirm-contract`;
  const result = await axios.post(URL, data);
  return result.status === 201 ? result.data : null;
};
