import React, { useContext, useMemo, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Title } from 'acon-mui/components/Board';
import InformationCard from 'acon-mui/components/Board/InformationCard';
import { STATUS_DRAFT_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { displayState, originalDocumentData, priceChangedState, priceState, selectedFieldsState, statusState } from 'boards/DetailBoardWrite/recoil';
import { numberWithCommas } from 'lib/formatNumber';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Wrap } from './style';
import { AppContext } from 'app';

export default (({ isAdmin }) => {
    const { showAlertMessage } = useContext(AppContext);
    // 번역도구 
    const { t } = useTranslation();
    // URL 파생 정보 
    const { lang } = useParams<{ lang?: string; }>();
    const [display] = useRecoilState(displayState);
    const priceChanged = useRecoilValue(priceChangedState);
    const originalData = useRecoilValue(originalDocumentData);
    const [selectedFields, setSelectedFields] = useRecoilState(selectedFieldsState);
    const [price, setPrice] = useRecoilState(priceState);
    const [status] = useRecoilState(statusState);
    const [isChecked, setIsChecked] = useState(false);
    const isCheckboxVisible = useMemo(() => [STATUS_DRAFT_ON_OPEN].includes(status), [status]);
    const disabled = useMemo(() => (isCheckboxVisible && !isChecked) || display.isDisp, [isCheckboxVisible, display.isDisp, isChecked]);

    // 단가 변경 이벤트 처리기 메소드 
    const onChangeSalePrice = e => {
        if (e.target.value) {
            const onlyNumberText = e.target.value.replace(/[^0-9]/g, '');
            if (onlyNumberText.length === 0) {
                setPrice(null);
                e.target.value = null;
            } else {
                const onlyNumber = Number(onlyNumberText);
                let val = lang === 'ko' ? onlyNumber : onlyNumber * 1000;
                setPrice(val);
                e.target.value = numberWithCommas(onlyNumber);
            };
        } else {
            setPrice(null);
        }
    };
    const onChangeCheckboxItem = (value, checked) => {
        setPrice(checked ? 0 : null);
    };
    const handleChecked = (checked, val) => {
        if (checked) {
            if (priceChanged && new Date().getTime() - new Date(priceChanged)?.getTime() < 1000 * 60 * 60 * 24 * 30) {
                showAlertMessage(
                    t('document.priceChange.warning.title'), 
                    t('document.priceChange.warning.description'), 
                    { callback: () => setIsChecked(false) }
                );
                return;
            } else {
                showAlertMessage(
                    t('document.priceChange.notice.title'), 
                    t('document.priceChange.notice.description'), 
                    { 
                        callback: () => {
                            setIsChecked(true);
                            setSelectedFields([...selectedFields, val]);
                        }
                    }
                );
                return;
            }
        }
        if (!checked) {
            setIsChecked(checked);
            setPrice(originalData?.price);
            setSelectedFields(selectedFields.filter(item => item !== val));
        };
    };

    return (
        <Wrap>
            <Box display="flex" alignItems="center">
                <Title
                    checkboxVisible={isCheckboxVisible}
                    isChecked={isChecked}
                    onClick={(value, checked) => handleChecked(checked, t('goods.price'))}
                >
                    {t('goods.price')} {lang !== 'ko' && '(USD)'}
                </Title>
                <FormGroup sx={{ ml: 'auto', '& label': { margin: '0 !important' } }}>
                    <FormControlLabel
                        control={<Checkbox 
                            checked={price === 0} 
                            onChange={onChangeCheckboxItem} 
                            disabled={disabled}
                        />}
                        label={t('freeSource')}
                    />
                </FormGroup>
            </Box>
            <Box position="relative" mb={4}>
                <TextField
                    type="text"
                    disabled={disabled || price === 0}
                    {...typeof price === 'number' && { 
                        value: lang === 'ko' ? numberWithCommas(price) : numberWithCommas(price / 1000) 
                    }}
                    onChange={onChangeSalePrice}
                    fullWidth
                    inputProps={{ maxLength: 13, }}
                    InputProps={{
                        endAdornment: (() => {
                            switch (lang) {
                                case 'ko':
                                    return '원';
                                default:
                                    return 'USD'
                            }
                        })()
                    }}
                    sx={{ marginTop: '4px', }}
                />
            </Box>
            {price === 0 && (
                <InformationCard type="warning">
                    {t('document.price.warning')}
                </InformationCard>
            )}
        </Wrap >
    );
});