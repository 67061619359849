import React, { useState, useContext, useEffect, useRef } from 'react';
import Loading from 'common/editor/Loading';
import { useMutation } from '@apollo/client';
import { AppContext } from 'app';
import { STATUS_CODE } from './constants';
import { mergeDeep } from '@apollo/client/utilities';
import { UPDATE_USER_V2 } from 'api/mutations';
import CreatorInfo from './components/user/info';
import CreatorInfoAdmin from './components/admin/info';
import PaymentInfoAdmin from './components/admin/payment';
import PaymentInfo from './components/user/payment';
import { getSignImg, uploadFile } from 'api';
import { PLAN_TYPE } from 'user/value/PlanType';
import axios from 'axios';
import { nestedObject } from './util';
import InputSignBox from 'user/component/InputSignBox';
import { BlackButtonModal } from '../component/modal/Modal';
import * as Styled from './style';
import dateFormat from 'dateformat';
import { confirmContract, updateContract } from 'api/contract';

const Component = (props) => {
  const { userInfo } = useContext(AppContext);
  const { id, data, downloadTerm } = props;
  const { isAdmin } = userInfo;

  // 회원 정보 로딩 여부
  const [getBlob, setGetBlob] = useState(null);
  const [changeData, setChangeData] = useState(false);
  const [isNameChanged, setIsNameChanged] = useState(false);
  const [isSignatureChanged, setIsSignatureChanged] = useState(false);
  const [signFile, setSignFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState({
    contentsText: null,
    isDisplay: false,
    isReload: false,
  });

  // 필수 입력 값 미 입력시 상단으로 표시하기 위한 ref
  const inputRefs = useRef([]);
  inputRefs.current = [];

  // 회원 정보 변경
  const [updateUser] = useMutation(UPDATE_USER_V2);

  // 마이페이지 데이터 변경 이벤트
  const onChangeData = (key, value) => {
    // 이름이 변경된 경우 계약서를 새로 갱신해야함
    if (key === 'data.user.name') {
      // 해외쪽은 현재 사업자가 없어서 개인만 존재함
      setIsNameChanged(true);
    }

    if (key === 'reference') {
      const newChangeData = { ...changeData, reference: value };
      setChangeData(newChangeData);
      return;
    }

    const changedData = nestedObject(key, value);
    if (changedData?.type === '1' && changedData.subType === undefined) {
      changedData.subType = '0';
    } else if (changedData.type === '0') {
      delete changedData.subType;
    }

    const newChangeData = mergeDeep(changeData, changedData);
    setChangeData(newChangeData);
  };

  const onChangeSign = (e) => {
    const file = e.target.files[0];
    setIsSignatureChanged(true);
    setSignFile(file);
  };

  // 회원 정보 조회 후 로딩 상태 변경
  useEffect(() => {
    if (data) {
      const files = {
        idCard: data.user.settle?.idCard,
        limitedTaxApl: data.user.settle?.limitedTaxApl,
      };
      const initData = {
        ...data,
        reference: data.user.reference.map((x) => ({ url: x.url })),
        comment: data.user?.accept?.comment,
        prevStatus: data.user?.accept?.status,
        prevModifyStatus: data.user?.accept?.modifyStatus,
        modifyRejectComment: data.user?.accept?.modifyRejectComment,
        modifyApproveComment: data.user?.accept?.modifyApproveComment,
        type: data.user?.type,
        subType: data.user?.subType,
        brandId: null,
        isExistBrand: false,
        file: {},
      };

      for (const key in files) {
        const file = files[key];

        // 저장 형식에 맞춤
        if (file) {
          initData.file[key] = {
            fullName: file?.fullName,
            id: file?.id,
            path: file?.path,
            size: file?.size,
          };
        }
      }

      onChangeData('data', initData);
    }
  }, [data]);

  // 필수 값 체크
  const validationCheck = () => {
    let isError = false;
    const userInfo = changeData.data.user;
    const userPlan = changeData.data.userPlan;

    // 일반 정보 필수 값 - 필명
    const brandName = !userInfo.brand.name;
    // 일반 정보 필수 값 - 입점 검토 자료
    const reference = changeData.data.reference.length === 0 || changeData.data.reference.some((x) => !x.url);
    // 일반 정보 필수 값 - 이름
    const name = !userInfo.name;
    // 일반 정보 필수 값 - 국가
    const country = !userInfo.settle?.country;
    // 일반 정보 필수 값 - 페이팔 계정
    const paypalAccount = !userInfo.settle?.bankAccountNumber;
    // 일반 정보 필수 값 - 수정 상태가 승인이면서 수수료율이 없을 경우
    const commissionRate = (userPlan?.commissionRate ?? -1) < 0 && userInfo.accept.status === STATUS_CODE.APPROVE;
    // 반려이면서 사유가 입력되지 않은 경우
    const userAcceptComment = !userInfo.accept.comment && userInfo.accept.status === -1;
    // 브랜드 코드
    const brandCode = userInfo.accept.status === STATUS_CODE.APPROVE && !userInfo.brand?.godoBrandCd && !changeData.data?.brandId;
    // 수정 - 상태가 반려일시 반려사유 안적었을 경우
    const modifyRejectComment = !userInfo.accept.modifyRejectComment && changeData.data.prevStatus === STATUS_CODE.APPROVE && userInfo.accept.modifyStatus === STATUS_CODE.REJECT;
    // 수정 - 상태가 승인일시 승인사유 안적었을 경우
    const modifyApproveComment =
      !userInfo.accept.modifyApproveComment &&
      changeData.data.prevStatus === STATUS_CODE.APPROVE &&
      userInfo.accept.modifyStatus === STATUS_CODE.APPROVE &&
      changeData.data.prevModifyStatus !== STATUS_CODE.APPROVE;

    if (!isAdmin) {
      isError = brandName || reference || name || country || paypalAccount;
    } else {
      isError = commissionRate || brandName || userAcceptComment || brandCode || modifyRejectComment || modifyApproveComment;
    }

    return isError;
  };

  // modal open
  const onOpenModal = (text, isReload) => {
    setModalData({
      contentsText: text,
      isDisplay: true,
      isReload,
    });
  };

  // 모달 닫기 이벤트
  const onCloseModal = () => {
    setModalData({
      ...modalData,
      isDisplay: false,
      isReload: false,
    });

    modalData.isReload && window.location.reload();
  };

  // 전체 항목 저장
  const onAllSave = async () => {
    const userData = changeData.data;
    const userInfo = userData.user;
    const userSaveData = {
      name: userInfo.name,
      settle: {
        bankAccountNumber: userInfo.settle?.bankAccountNumber,
        country: userInfo.settle?.country,
      },
      contactAdditional: userInfo.contactAdditional,
      contact: userInfo.contact,
      file: userData.file,
      reference: userData?.reference || [],
      subType: userData?.subType,
    };

    // 관리자 저장 항목
    if (isAdmin) {
      userSaveData.account = userInfo.account;
      userSaveData.isExistBrand = userData?.isExistBrand;
      userSaveData.type = userData?.type;
      userSaveData.accept = {
        status: userInfo.accept.status,
        comment: userInfo.accept.comment,
        modifyStatus: userInfo.accept.modifyStatus,
        modifyRejectComment: userInfo.accept.modifyRejectComment,
        modifyApproveComment: userInfo.accept.modifyApproveComment,
      };
      userSaveData.plan = {
        type: userData.userPlan?.type || 0,
        isExclusive: userData.userPlan?.isExclusive || false,
        commissionRate: userData.userPlan?.commissionRate,
      };
      userSaveData.brand = {
        koName: userInfo.brand?.koName,
        enName: userInfo.brand?.enName,
        cnName: userInfo.brand?.cnName,
        jpName: userInfo.brand?.jpName,
      };

      if (userData?.brandId) {
        // 기존 브랜드 선택하기 - 브랜드 코드
        userSaveData.brandId = userData?.brandId;
      } else {
        if (userInfo.brand?.godoBrandCd) {
          // 고도몰 브랜드코드 직접 입력
          userSaveData.brand = {
            ...userSaveData.brand,
            godoBrandCd: userInfo.brand.godoBrandCd,
          };
        }
      }
    }

    // 필수 입력값을 입력하지 않는다면 해당 input으로 이동
    inputRefs.current.some((input) => {
      if (input?.value || input?.state?.value) {
        return false;
      }

      let scrollTop = 0;
      if (input?.select && input?.state && !input.state?.value) {
        // rc-select 값 확인 후 위치 알아내기
        scrollTop = input.select?.controlRef?.parentElement?.offsetTop || 0;
      } else if (!input.value) {
        scrollTop = input.offsetTop || 0;
        input.focus();
      }

      window.scrollTo(0, scrollTop - 150);
      return true;
    });

    if (userInfo?.snsUrl) {
      userSaveData.snsUrl = userInfo.snsUrl;
    }

    // 필수 항목 값 체크
    if (validationCheck()) {
      return false;
    }

    // 저장 완료되면 완료 모달 띄움.
    const isSave = await onSave(userSaveData);
    if (isSave) {
      onOpenModal(isAdmin ? '저장되었습니다.' : 'Submitted', true);
    }
  };

  // 저장 버튼 클릭 시
  const onSave = async (userData) => {
    const signLang = data.user.settle?.signLang || 'en'; // user_settle.sign_lang: 'ko', 'en', 'zh', null, ''
    const promiseList = [];

    // Loading 표시
    setIsLoading(true);

    // 관리자가 입점 승인한 경우
    if (userInfo.isAdmin && changeData?.accept?.status === 1) {
      const contact = data.user.contact;
      const PIN = data.user.settle.nationalIdNumber;
      const { type, isExclusive, commissionRate } = data.userPlan || {};
      const name = isNameChanged ? (data.user.type === '0' ? changeData.name : changeData.companyName) : data.user.type === '0' ? data.user.name : data.user.companyName;
      const planType = data.userPlan && PLAN_TYPE({ type, isExclusive });

      // 우선 여기서 확정을 해야하기 때문에 await로 받아야함 (즉, create -> confirm -> update 순서보장이 되어야함)
      await confirmContract({
        userId: id,
        locale: userInfo?.settle?.locale,
        userName: name,
        userContact: contact,
        userPin: PIN,
        planType,
        commissionRate,
        password: changeData?.brand?.godoBrandCd || data.user.brand.godoBrandCd,
      });
      // 이름이나 서명이 변경된 경우
    } else if (isNameChanged || isSignatureChanged) {
      const name = isNameChanged ? (data.user.type === '0' ? changeData.name : changeData.companyName) : data.user.type === '0' ? data.user.name : data.user.companyName;

      const signaturePath = isSignatureChanged ? await uploadFile(data.user.type === '0' ? await getBlob() : signFile, data.user.settle.sign.fullName) : null;

      const contact = data.user.contact;
      const PIN = data.user.settle.nationalIdNumber;
      const { type, isExclusive, commissionRate } = data.userPlan || {};
      const planType = data.userPlan && PLAN_TYPE({ type, isExclusive });

      await updateContract({
        userId: id,
        signatureAzureStoragePath: signaturePath,
        locale: userInfo?.settle?.locale,
        userName: name,
        userContact: contact,
        userPin: PIN,
        planType,
        commissionRate,
        password: changeData?.brand?.godoBrandCd || data.user.brand.godoBrandCd,
      });

      isNameChanged && setIsNameChanged(false);
      isSignatureChanged && setIsSignatureChanged(false);
    }

    // 고도브랜드코드가 변경된 경우
    if (userInfo.isAdmin && changeData?.brand?.godoBrandCd) {
      const contact = data.user.contact;
      const PIN = data.user.settle.nationalIdNumber;
      const { type, isExclusive, commissionRate } = data.userPlan || {};
      const name = isNameChanged ? (data.user.type === '0' ? changeData.name : changeData.companyName) : data.user.type === '0' ? data.user.name : data.user.companyName;
      const planType = data.userPlan && PLAN_TYPE({ type, isExclusive });

      await updateContract({
        userId: id,
        locale: userInfo?.settle?.locale,
        userName: name,
        userContact: contact,
        userPin: PIN,
        planType,
        commissionRate,
        password: changeData?.brand?.godoBrandCd || data.user.brand.godoBrandCd,
      });
    }

    /********************************************************************************/

    // 변경된 파일이 존재할경우
    if (userData.file) {
      for (const key in userData.file) {
        const target = userData.file[key];

        // file 객체가 없을 경우
        if (!target || !target?.file) {
          delete userData.file[key];
          continue;
        }

        if (target?.id) {
          // 이미 파일이 존재할경우 덮어쓰기
          promiseList.push(uploadFile(target.file, target.fileName));
          delete target.file;
          delete target.fileName;
          continue;
        }

        const result = await uploadFile(target.file);
        target.fullName = result.key;
        delete target.file;
        delete target.fileName;
      }
    }

    // 업데이트된 유저 정보를 가공하여 디비에 저장
    if (typeof userData?.accept?.status === 'number') {
      if (userData.accept.status !== 1) {
        try {
          delete userData.brand.godoBrandCd;
        } catch {}
      }
    } else if (data.user.accept.status !== 1) {
      try {
        delete userData.brand.godoBrandCd;
      } catch {}
    }

    if (userData.brand?.enName) {
      userData.brand.name = userData.brand.enName;
    }
    // 저장
    promiseList.push(
      updateUser({
        variables: {
          id,
          user: userData,
        },
      }),
    );

    let returnState = false;
    try {
      await Promise.all(promiseList);
      returnState = true;
    } catch (err) {
      onOpenModal('오류 발생, 잠시 후 다시 시도 하십시오', true);
    }

    // 로딩 해제
    setIsLoading(false);

    return returnState;
  };

  return (
    <Styled.Container>
      {changeData && (
        <>
          {/* 일반 정보 */}
          {userInfo.isAdmin ? (
            <CreatorInfoAdmin data={changeData?.data} onChangeData={onChangeData} downloadTerm={downloadTerm} inputRefs={inputRefs} />
          ) : (
            <CreatorInfo
              data={changeData?.data}
              onChangeData={onChangeData}
              downloadTerm={downloadTerm}
              userInfo={userInfo}
              onSave={onSave}
              onOpenModal={onOpenModal}
              inputRefs={inputRefs}
            />
          )}

          {/* 정산 정보 */}
          {userInfo.isAdmin ? (
            <PaymentInfoAdmin id={id} userInfo={userInfo} data={changeData?.data} onChangeData={onChangeData} onOpenModal={onOpenModal} />
          ) : (
            <PaymentInfo data={changeData?.data} onChangeData={onChangeData} onSave={onSave} onOpenModal={onOpenModal} inputRefs={inputRefs} />
          )}

          {/* 반려일 시 서명 작성 영역 표시 */}
          {!userInfo.isAdmin && data.user?.accept.status === STATUS_CODE.REJECT && (
            <section>
              <h4>Entry Agreement</h4>
              <Styled.Contents>
                {data.user.type === '0' ? (
                  <Styled.Field label="Signature">
                    <Styled.Input disabled={true} defaultValue={data.user.settle.sign?.fullName} />
                    <InputSignBox summary={'Sign Here'} button={'Reset'} setGetBlob={setGetBlob} setIsDrawn={setIsSignatureChanged} />
                  </Styled.Field>
                ) : (
                  <Styled.Field label="인감/서명">
                    <Styled.FileInputComponent value={data.user.settle.sign.fullName} onChange={onChangeSign} />
                  </Styled.Field>
                )}
              </Styled.Contents>
            </section>
          )}

          {(userInfo.isAdmin || data.user.accept.status === -1) && <button onClick={onAllSave}>{isAdmin ? '저장' : 'Submit'}</button>}
        </>
      )}

      {modalData.isDisplay && <BlackButtonModal contentsText={modalData.contentsText} onClose={onCloseModal} />}

      {isLoading && <Loading />}
    </Styled.Container>
  );
};

export default Component;
